.wrapper {
    width: 96%;
    padding: 12px 0px 4px 0px;
    margin-left: 36px;
    overflow: hidden;
}

.formWrapper {
    width: 97%;
    padding: 30px;
    margin: 6% auto;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 3px 3px 15px var(--light-grey);
    margin-bottom: 20px;
}

.formWrapper .innerWrap {
    padding: 10px;
    background: var(--light-low);
    border-radius: 6px;
    margin-bottom: 20px;
}
.formWrapper .logo {
    background: linear-gradient(90deg, var(--primary) 0%, var(--faded-orange) 100%);
    padding: 14px 30px 15px 10px;
    text-align: center;
    margin: -30px -30px 30px -30px;
    border-radius: 10px 10px 0 0;
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
}

.formWrapper .section {
    color: var(--primary);
    margin-top: 0px;
    margin-bottom: 6px;
    font-size: 20px;
    font-weight: 500;
}

.section p {
    text-align: center;
    margin-top: -30px;
}

.icon {
    position: absolute;
    z-index: 1000;
    font-size: 1.2em;
    color: var(--white);
    transform: rotate(45deg);
    right: 54px;
    margin-top: -14px;
    opacity: 0.8;
}

.section .leaf {
    position: absolute;
    color: var(--primary);
    font-size: 12px;
    margin-top: -6px;
    margin-left: 12px;
}

/* collapsible */

.container {
    margin: 10px auto;
    width: 100%;
}

.container ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.container .labelMain {
    display: block;
    cursor: pointer;
    padding: 20px 30px;
    background-color: var(--white);
    box-shadow: 1px 2px 12px var(--light-grey);
    border-radius: 6px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--dark-grey);
}

.container .labelMain:hover {
    background: var(--primary);
    color: var(--white);
    opacity: 0.8;
}

.container input[type='checkbox'] {
    position: absolute;
    left: -9999px;
}

.container input[type='checkbox'] ~ ul {
    height: 0;
    transform: scaleY(0);
}

.container input[type='checkbox']:checked ~ ul {
    height: 100%;
    transform-origin: top;
    transition: transform 0.5s ease-out;
    transform: scaleY(1);
}

.container input[type='checkbox']:checked + label {
    background-color: var(--faded-orange);
    border-bottom: 1px solid var(--white);
    color: var(--white);
}

.container .button {
    background-color: var(--primary);
    padding: 8px 16px;
    color: var(--white);
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin: 0 auto;
    letter-spacing: 1px;
    border: none;
    border-radius: 4px;
    width: 10em;
    display: block;
    margin-top: 2em;
    cursor: pointer;
    font-weight: 500;
}

.container .button:hover {
    opacity: 0.8;
    transition: 200ms;
}

/* form */
.innerContainer {
    width: 100%;
    background-color: var(--white);
    border-radius: 10px;
    margin-top: 4px;
    padding: 20px 2px;
    padding-bottom: 26px;
    margin-bottom: 40px;
}

.innerMargin {
    margin-bottom: -48px;
}

.innerContainer p {
    display: block;
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 1.1em;
    color: var(--secondary);
    font-weight: 500;
}

.collab {
    width: 95%;
    margin: 0 auto;
    padding: 10px 30px;
    background-color: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
}

.collab label {
    display: block;
    font-size: 14px;
    color: var(--dark-grey);
    margin-bottom: 14px;
    font-weight: 450;
    margin-top: 10px;
}

.collab label:hover {
    background-color: none;
}

.collab .select {
    background-color: var(--white);
    font-size: 14px;
}

.collab .select option {
    font-size: 14px;
}

.collab input[type='text'],
.collab input[type='email'],
.collab input[type='number'],
.collab input[type='password'],
.collab input[type='date'],
.collab input[type='pass'],
.collab input[type='datetime-local'],
.collab input[type='file'],
.formWrapper input[type='tel'],
.collab textarea,
.collab select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 2px solid var(--white);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.35);
    font-size: 14px;
    color: var(--dark-grey);
    outline: none;
    cursor: pointer;
}

.collab input[type='text']:focus,
.collab input[type='email']:focus,
.collab input[type='number']:focus,
.collab input[type='password']:focus,
.formWrapper input[type='tel']:focus,
.collab textarea:focus {
    border: 2px solid var(--grey2);
}

.formGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
}

.fetchLabel {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 1px 2px 4px var(--light-grey);
    margin-bottom: 20px;
    text-transform: capitalize;
}

.fetchLabel p {
    display: inline;
    position: relative;
    visibility: hidden;
}

.optAll {
    position: absolute;
    margin-top: -2em;
    background-color: var(--light-low);
    width: 40%;
    border-radius: 4px;
    padding: 2px 4px;
    overflow-y: auto;
}

.optAllDoc {
    position: absolute;
    margin-top: 4.8em;
    background-color: var(--light-low);
    width: 40%;
    border-radius: 4px;
    padding: 2px 4px;
    overflow-y: auto;
    max-height: 30em;
    border: 1px solid var(--light-grey);
}

.optSelect {
    box-shadow: 1px 2px 4px var(--light-grey);
    color: var(--dark);
    padding: 10px 6px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    text-transform: capitalize;
}

.optSelect:hover {
    background-color: var(--primary);
    color: var(--white);
}

.refMargin {
    margin-bottom: 3em;
}

.buttonAdd {
    float: right;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    background-color: var(--secondary);
    color: var(--white);
    font-size: 14px;
}

.buttonAdd:hover {
    opacity: 0.8;
}

.formGrid > label > .tk {
    color: var(--green);
}

.starSign {
    color: var(--red);
}

.relative {
    position: relative;
}

.cross {
    position: absolute;
    color: var(--red);
    top: 1.35em;
    right: 6px;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
}

.cross:hover {
    opacity: 0.8;
    transition: 200ms;
    cursor: pointer;
}

@media (max-width: 420px) {
    .formGrid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .wrapper {
        width: 95%;
        margin-left: 4px;
        margin-top: -30px;
    }

    .wrapper .button {
        display: block;
        float: none;
        margin: 0 auto;
        padding: 7px 30px;
        font-size: 10px;
        font-weight: 500;
    }

    .formWrapper {
        padding: 30px;
    }

    .formWrapper .logo {
        padding: 6px;
        font-size: 12px;
        font-weight: 500;
    }

    .formWrapper .form {
        margin: -16px;
    }

    .formWrapper .section {
        margin-top: -6px;
        font-size: 14px;
    }

    .icon {
        font-size: 0.8em;
        right: 20px;
        margin-top: -22px;
    }

    .section .leaf {
        font-size: 10px;
        margin-top: -6px;
        margin-left: 10px;
    }

    .formWrapper .innerWrap {
        padding: 6px;
    }

    .container .labelMain {
        padding: 14px 30px;
        font-size: 12px;
    }

    .innerContainer {
        padding: 6px 0px;
    }

    .collab {
        padding: 12px;
    }

    .formWrapper label {
        font-size: 12px;
    }

    .formWrapper .formMargin {
        margin-top: -28px;
    }

    .formGrid label:nth-child(2) {
        margin-top: -28px;
    }

    .formWrapper .select {
        font-size: 10px;
    }

    .formWrapper .select option {
        font-size: 10px;
    }

    .formWrapper input[type='text'],
    .formWrapper input[type='email'],
    .formWrapper input[type='number'],
    .formWrapper input[type='tel'],
    .formWrapper input[type='password'],
    .formWrapper input[type='date'],
    .formWrapper input[type='datetime-local'],
    .formWrapper textarea,
    .formWrapper select {
        padding: 6px;
        font-size: 12px;
    }

    .formWrapper input[type='date'],
    .formWrapper input[type='datetime-local'] {
        font-size: 10px;
    }

    .formWrapper .button {
        margin-top: 20px;
        padding: 6px 10px;
        font-size: 12px;
        width: 12em;
    }

    .fetchLabel {
        padding: 6px;
        font-size: 12px;
    }

    .optAll {
        margin-top: -6em;
        width: 82%;
    }

    .optAllDoc {
        margin-top: 4em;
        width: 82%;
    }

    .optSelect {
        font-size: 10px;
    }

    .buttonAdd {
        padding: 6px 16px;
        font-size: 12px;
    }
}
