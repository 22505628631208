.container {
    margin: 15px 0px;

    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    row-gap: 10px;
    background: var(--white);
    padding: 20px;
    border-radius: 6px;
}
.container > input {
    border: 1px rgb(177, 177, 177) solid;
    padding: 0.3rem;
    padding-left: 1em;
    border-radius: 0.3rem;
    outline: none;
    font-size: 12px;
}
.container > input::placeholder {
    font-size: 12px;
}
.dateBlock {
    display: flex;
    gap: 5px;
    place-items: center;
    justify-self: center;
}
.dateBlock > svg {
    color: var(--primary);
}
.container > div > input {
    border: 1px rgb(177, 177, 177) solid;
    padding: 0.3rem;
    border-radius: 0.3rem;
    outline: none;
    font-size: 12px;
}
.container > input:focus {
    border: 1px solid var(--primary);
}
.container > div > input:focus {
    border: 1px solid var(--primary);
}
.sliderContainer {
    display: flex;
    place-items: center;
    gap: 12px;
}
.sliderContainer > input {
    width: 25%;
    padding: 0.4rem !important;
}
.sliderContainer > input::placeholder {
    font-size: 12px;
}
.sliderContainer > div {
    font-size: 12px;
    color: var(--secondary);
}
.amount {
    color: var(--secondary);
    font-size: 14px;
    margin: 0px;
}
