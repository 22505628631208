.close {
    display: flex;
    justify-content: flex-end;
    place-items: center;
    /* margin-bottom: 10px; */
}
.close > button {
    display: flex;
    justify-content: center;
    place-items: center;
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: #f86809;
    background: #fff1e6;
}
.close > button:hover {
    opacity: 0.8;
}

.header {
    flex-grow: 1;
    text-align: center;
    transform: translateY(-50%);
}
.header > p {
    font-size: 16px;
    margin: 0px;
    font-weight: 500;
    line-height: 24px;
    color: var(--dark);
    cursor: default;
}
.contentContainer {
    display: flex;
    place-items: center;
    justify-content: center;
    background: var(--white);
    margin: 15px;
    border-radius: 6px;
    display: none;
    height: 100%;
    padding-right: 15px;
    margin: 1cm;
    margin-top: 0cm;
}
.content {
    background: var(--white);
    font-family: Arial;
    padding: 15px;
    width: 100%;

    margin-top: 1cm;
}
.contentHeader {
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-bottom: 35px;
}
.img {
    height: 20px;
    width: 139px;
    flex-shrink: 0;
    object-fit: cover;
}
.contentHeader > p {
    max-width: 30%;
    text-align: right;
    font-weight: 700;
    margin: 0px;
    font-size: 18px;
    line-height: 20.7px;
    color: #1b497e;
    letter-spacing: 0.03em;
    cursor: default;
}
.formData {
    margin-top: 20px;
    margin-bottom: 25px;
}
.formRow {
    display: grid;
    grid-template-columns: 30% 70%;
    margin: 8px 0px;
    align-items: center;
}
.formRow > p {
    color: var(--dark);
    font-weight: 700;
    margin: 0px;
    font-size: 12px;
    line-height: 13.8px;
    letter-spacing: 0.03em;
}
.numberField {
    display: flex;
}
.checkGroup {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 10px;
}

.numberSetStart {
    margin-right: 8px;
}
.numberSet {
    margin-left: 8px;
    margin-right: 8px;
}
.checkItem {
    display: flex;
    place-items: center;
    gap: 10px;
    margin-right: 20px;
}
.checkItem > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 13.8px;
    letter-spacing: 0.03em;
    color: var(--dark);
}
.checkbox {
    height: 14px;
    width: 14px;
    border: 2px solid #ff6700;
    color: #ff6700;
    display: flex;
    justify-content: center;
    place-items: center;
    border-radius: 2px;
}
.formField {
    width: 100%;
    background: #e4effc;
    color: var(--dark);
    font-weight: 400;
    margin: 0px;
    font-size: 12px;
    line-height: 13.8px;
    letter-spacing: 0.03em;
    padding: 7px 0px 5px 8px;
}
.pagination {
    text-align: center;
    color: #1b497e;
    font-weight: 400;
    font-size: 11px;
    line-height: 12.65px;
    margin: 0px;
    letter-spacing: 0.03em;
    margin-top: 0.5cm;
}
.buttonContainer {
    display: flex;
    justify-content: center;
    place-items: center;
    gap: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
}
.download {
    border: none;

    background: var(--secondary);
    color: var(--white);
    padding: 8px 18px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}
.cancel {
    border: none;

    background: var(--red);
    color: var(--white);
    padding: 8px 18px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}
.cancel:hover,
.download:hover {
    opacity: 0.8;
}
.page2HeaderGap {
    margin-top: 12px;
}
.pagiGap2 {
    margin-bottom: 8.7cm;
}
@media print {
    .contentContainer {
        display: block;
    }
}
@page {
    size: A4;
}
