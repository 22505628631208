.formBox {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0px #f2f2f2;
    padding: 16px 0;
    margin-bottom: 20px;
}

.formBox .hide {
    display: none;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 10px;
}

.formLayout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px 30px;
}
.formBox > div {
    padding-left: 20px;
    padding-right: 20px;
}
.formLayout2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px 30px;
    padding-left: 20px;
    padding-right: 20px;
}
.title {
    font-size: 15px;
    line-height: 25px;
    color: var(--text-bold-color);
}
.spreadTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.spreadTitle > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 20px;
}
.spreadTitle .action {
    width: 30px;
    height: 30px;
    border: 1px solid var(--pink);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.title > div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.title > div svg path {
    fill: var(--primary);
}
.title.borderBottom {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f2f2f2;
}
.title p {
    color: var(--text-light);
    font-size: 12px;
    line-height: 18px;
    margin-top: 3px;
}

label {
    font-size: 13px;
    line-height: 23px;
    color: var(--text-bold-color);
}

label sup {
    vertical-align: bottom;
    color: var(--primary);
    font-weight: 600;
    font-size: 14px;
}

input {
    padding: 12px;
    font-size: 14px;
    border: 1px solid;
    border-color: var(--border-color);
    border-radius: 6px;
    outline: none;
    padding: 10px 20px;
}

input:focus {
    border-color: var(--primary);
}
input::placeholder {
    font-size: 13px;
    line-height: 23px;
    color: var(--placeholder-color);
}

/* Error message styling */
p[role='alert'] {
    color: #f44336;
    font-size: 12px;
    margin-top: -8px;
}

/* Submit button styling */
input[type='submit'] {
    padding: 12px 16px;
    background-color: #4e9af1;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

input[type='submit']:hover {
    background-color: #3578e5;
}

/* Input error state styling */
input[aria-invalid='true'] {
    border-color: #f44336;
}
.quantity {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid var(--border-color);
}
.quantity.amount > input {
    text-align: left;
}
.quantity > div {
    width: 50px;
    height: 40px;
    background: #f1f1f2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    top: 0;
}
.quantity > input {
    border: none;
    color: #000;
    text-align: center;
}
.quantity .inputValue {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    background: transparent;
    color: #000;
    padding-left: 20px;
    justify-content: left;
    width: 100%;
}

.quantity > div:first-child {
    left: 0;
}
.quantity > div:last-child {
    right: 0;
}
.quantity.focus,
.quantity:focus-within {
    border: 1px solid var(--primary);
    box-shadow: 0 0 0 2px #ffe6d8;
}

.quantity.focus > div,
.quantity:focus-within > div {
    background: var(--pink);
}
.quantity.focus svg path,
.quantity:focus-within > svg path {
    fill: #000;
}
.radioBtn > div > div {
    border: 1.5px solid #757575;
    width: 16px;
    height: 16px;
    background: transparent;
    border-radius: 50%;
}
.radioBtn > div > div > div {
    display: none;
}
.radioBtn > div > div.active {
    border: 1.5px solid var(--primary);
    position: relative;
}
.radioBtn > div > div.active > div {
    display: block;
    width: 100%;
    height: 100%;
    background: var(--primary);
    border-radius: 50%;
    border: 2px solid #fff;
}
.checkboxes,
.radioBtn {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 0 20px;
    margin-top: 12px;
}
.checkboxes > div,
.radioBtn > div {
    border: 1px solid var(--border-color);
    border-radius: 6px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 20px 10px 20px;
}
.checkboxes > div.active {
    border: none;
}
.checkboxes > div > div {
    display: inline-flex;
}
.checkboxes svg {
    border: 2px solid #757575;
    border-radius: 3px;
}
.checkboxes .active svg {
    border: none;
}
.checkboxes svg path {
    fill: transparent;
}
.checkboxes .active svg path {
    fill: revert-layer;
}

/* demo description */

.descriptionDemo {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    margin: 0 20px;
    margin-top: 12px;
    padding: 16px 0 !important;
}

.descriptionDemo h3 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: var(--text-bold-color);
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--border-color);
    padding-left: 18px;
    padding-right: 18px;
}

.descriptionDemo > div {
    padding: 0 18px;
}

.demoText {
    font-size: 13px;
    line-height: 20px;
    color: #555;
}

.featuresList {
    padding: 0 18px;
    margin-top: 10px;
}

.featuresList li {
    font-size: 13px;
    line-height: 27px;
    color: #555;
    list-style: disc;
}

.btnGroup {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
}
.customizePrice {
    border-top: 1px solid #f2f2f2;
    margin-top: 12px;
    padding-top: 12px;
}
.customizePrice .formLayout {
    display: flex;
    gap: 30px;
    align-items: center;
}

.customizePrice .formGroup {
    padding-bottom: 0;
    width: 400px;
}
.warning {
    color: #ff0a0a;
    font-size: 13px;
    line-height: 23px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.warning svg path {
    fill: revert-layer;
}

.warning.lastChild {
    margin-left: 20px;
    margin-top: 10px;
}

.formGroup input {
    font-size: 14px;
    border: 1px solid;
    border-color: var(--border-color);
    border-radius: 6px;
    outline: none;
    padding: 10px 20px;
}

.formGroup .error {
    border-color: #ff0a0a;
}

.attributeBtn,
.itemGroupBtn {
    padding: 3px 12px;
    background: var(--light-pink);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    line-height: 23px;
    color: var(--text-bold-color);
    text-transform: capitalize;
}
.attribute .wrapper,
.itemGroup .wrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    position: relative;
}
.renderVariantWrapper {
    margin-top: 15px;
    border-top: 1px solid var(--border-color);
}
.renderVariant {
    margin: 12px 0 0 0;
    background: var(--secondary);
    border-radius: 6px;
    padding: 16px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.renderVariant .card .attributeBtn {
    background: #fff;
}
.renderVariant > div:first-child {
    display: flex;
    align-items: center;
    gap: 12px;
}

.renderVariant h5 {
    width: 90px;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    color: var(--text-bold-color);
    text-transform: capitalize;
}
.action button {
    background: var(--pink);
    border: 1px solid var(--primary);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    font-size: 12px;
    line-height: 22px;
    color: var(--text-bold-color);
    text-transform: capitalize;
}
.action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.action .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--pink);
    width: 30px;
    height: 30px;
    border-radius: 4px;
}

.action .icon svg path {
    fill: var(--text-light) !important;
}

.action button:first-child {
    padding: 4px 12px;
}

.action button:last-child {
    padding: 9px;
}
.marginCalculation {
    display: flex;
    gap: 30px;
    align-items: center;
}
.itemGroup {
    position: relative;
}
.itemGroup .submitBtn {
    background: linear-gradient(rgba(255, 102, 0, 1), rgba(250, 127, 42, 1));
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
    padding: 4px 26px;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    right: 7px;
    top: 6px;
}
