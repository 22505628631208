.container {
    background: var(--white);
    border-radius: 10px;
}
.whiteContainer {
    padding: 20px;
}

.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
}
.innerContainer > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 16px;
    margin-bottom: 6px;
    font-weight: 450;
}
.innerContainer > input[type='text'] {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;
    cursor: pointer;
    padding-left: 12px;
}
.innerContainer > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.half {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 30px;
    font-weight: 450;
}
.email {
    display: flex;
    justify-content: center;
    place-items: center;
    gap: 30px;
    font-weight: 450;
}
.email > div > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 16px;
    margin-bottom: 6px;
}

.half > div {
    width: 100%;
}
.fixedField {
    width: 100%;
    background: var(--white);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    cursor: default;
    min-height: 40px;
}
.addressField {
    width: 100%;
    background: var(--white);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    cursor: default;
    min-height: 150px;
}

.half > div > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 6px;
}
.half > div > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.half > div > input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;

    padding-left: 12px;
}
.buyerInput {
    width: 100%;
    display: flex;
    gap: 10px;
    place-items: center;
    margin-bottom: 15px;
}
.buyerInput > div > button {
    display: flex;
    border: none;
    background: var(--primary);
    color: var(--white);
    place-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.buyerInput > div > button:hover {
    opacity: 0.8;
}
.buyerInput > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.buyerInput > input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    /* margin-bottom: 15px; */
    color: var(--dark);
    border: none;

    padding-left: 12px;
}
.error {
    text-align: center;
    margin-bottom: 1em;
    font-weight: 600;
    color: rgb(225 29 72);
}
