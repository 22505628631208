.pagination_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 0 0 5em;
    background-color: var(--white);
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
    gap: 10px;
}

.page_item {
    margin: 0 0.25rem;
}

.page_link {
    padding: 5px 10px;
    font-size: 14px;
    text-decoration: none;
    border: 1px solid var(--border-color);
    color: black;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.page_link:hover {
    background-color: var(--primary);
    color: #fff;
}

.active .page_link {
    background-color: var(--primary);
    color: #fff;
    border-color: var(--primary);
}

.page_item.disabled .page_link {
    color: #6c757d;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #f8f9fa;
}

.page_form {
    display: flex;
    align-items: center;
    gap: 5px;
}

.page_input {
    width: 50px;
    padding: 5px;
    font-size: 14px;
    text-align: center;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.page_button {
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    background-color: var(--primary);
    color: white;
    cursor: pointer;
    border-radius: 4px;
}
