.container {
    background: var(--white);
    border-radius: 10px;
}
.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
}
.innerContainer > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 3px;
}
.innerContainer > .upload {
    width: 100%;
    background: var(--white);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    cursor: default;
    display: flex;
    place-items: center;
    justify-content: space-between;
}
.upload > div {
    display: flex;
    place-items: center;
    gap: 10px;
}
.upload > button {
    color: var(--primary);
    border: none;
    background: none;
    display: flex;
    place-items: center;
    cursor: pointer;
}
.upload > div > svg {
    color: var(--primary);
}
.innerContainer > input[type='text'] {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;
    cursor: pointer;
    padding-left: 12px;
}
.innerContainer > input[type='text']:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.half {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 30px;
}
.half > div {
    width: 100%;
}
.half > div > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 3px;
}
.half > div > input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;
    cursor: pointer;
    padding-left: 12px;
}
.half > div > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.buttonContainer {
    width: 100%;
    text-align: center;
}
.buttonContainer > button {
    margin: 10px 0px;
    border-radius: 6px;
    padding: 6px 13px;
    border: none;
    cursor: pointer;
    background: var(--blue);
    color: var(--white);
    font-weight: 600;
}
.buttonContainer > button:hover {
    opacity: 0.8;
}
.submitMessage {
    text-align: center;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
}
