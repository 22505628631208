.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
    padding-bottom: 12px;
    text-align: left;
}
.innerContainer > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 450;
}
.innerContainer > input[type='text'] {
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;
    padding-left: 12px;
    width: 100%;
}

.innerContainer > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.innerContainer > textarea {
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;
    padding-left: 12px;
    width: 100%;
}
.innerContainer > textarea:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.fileField {
    border: 1px dashed #c3bfbf;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    background: var(--white);
    text-align: center;
    margin: auto;
    margin-bottom: 15px;
}
.fileField > img {
    height: 28px;
    width: 28px;
}
.fileField > input {
    display: none;
}
.fileField > div {
    display: flex;
    justify-content: center;
    gap: 0.3rem;
    color: #828282;
    font-weight: 400;
    font-size: 0.875rem;
}
.fileField > div > p {
    text-decoration: underline;
    color: #fa6803;
}
.star {
    color: var(--deep-red);
}
.file {
    display: flex;
    gap: 8px;
    place-items: center;
    color: #271810;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
.file > img {
    height: 24px;
    width: 24px;
}

.submitContainer {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.submitContainer > button {
    border: none;
    padding: 6px 14px;
    background: var(--primary);
    color: var(--white);
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: -33px;
}
.submitContainer > button:hover {
    opacity: 0.8;
}
.submitMessage {
    display: flex;
    justify-content: center;
    margin-top: 6px;
    font-size: 13px;
    font-weight: 600;
    position: relative;
}
