.navbar {
    padding: 16px 20px 16px 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px var(--background) solid;
    position: sticky;
    top: 0;
    z-index: 50;
    min-height: 73px;
}

.navbarLeft {
    /* border: 1px red solid; */
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 10rem;
    height: 100%;
}

.navbarLeft > img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
}

.navbarRight {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;
    width: 100%;
    position: relative;
}
.navbarRight > div:not(:last-child) {
    position: relative;
}
.navbarRight > div:not(:last-child):after {
    content: '';
    position: absolute;
    height: 25px;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid rgba(27, 73, 126, 0.12);
}

.switch {
    background-color: var(--pink);
    border-radius: 2rem;
    padding: 5px 7px;
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.active {
    background-color: var(--primary);
    padding: 4px 14px;
    border-radius: 14px;
    color: white;
    font-size: 14px;
    line-height: 21px;
}

.inactive {
    padding: 4px 14px;
    background-color: white;
    border-radius: 14px;
    color: grey;
    font-size: 14px;
    line-height: 21px;
}

.notificationPanel {
    display: flex;
    gap: 10px;
}

.bar {
    width: 44px;
    height: 40px;
    background: var(--pink);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.notificationPanel .iconBox .icon {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(27, 73, 126, 0.12);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon svg path {
    fill: var(--text-light);
}
.profile {
    display: flex;
    gap: 14px;
    align-items: center;
}
.profile .layout {
    display: flex;
    gap: 8px;
    align-items: center;
}

.profile .sellerImage {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 6px;
}
.profile h5 {
    font-size: 14px;
    line-height: 24px;
    color: var(--text-bold-color);
}
.popUp {
    position: absolute;
    bottom: 0;
    right: 0;
    height: fit-content;
    padding: 16px 20px;
    border-radius: 6px;
    border: 1px solid rgba(27, 73, 126, 0.12);
    box-shadow: 0 6px 12px 0 rgba(242, 242, 242, 1);
    background: #fff;
    top: 117%;
    z-index: 100;
    text-align: center;
    min-width: 184px;

    /* Transition properties */
    opacity: 0;
    transform: translateY(20px);
    visibility: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
}

.popUp.active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.welcomeMessage h4 {
    font-size: 14px;
    line-height: 24px;
    color: #271810;
}
.welcomeMessage span {
    display: block;
    color: var(--primary);
    font-weight: 500;
}

.menuItems {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 28px;
    position: relative;
}
.menuItems .linkItem {
    padding: 0;
}
.menuItems .linkItem a {
    display: inline-flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-light);
}
.menuItems::before {
    content: '';
    width: 100%;
    height: 1px;
    background: rgba(27, 73, 126, 0.12);
    position: absolute;
    top: -13px;
}
.menuItems .linkItem a svg path,
.bar svg path {
    fill: transparent;
}

.menuItems .linkItem:hover a,
.menuItems .linkItem:hover svg,
.menuItems .linkItem.active a,
.menuItems .linkItem.active svg {
    color: var(--blue);
    fill: var(--blue);
}
