.tableContainer {
    margin: 30px 16px 200px 16px;
}
.headerContainer {
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-bottom: 10px;
}
.headerContainer > h2 {
    font-size: 15px;
    font-weight: 500;
    color: #271810;
}
.buttonContainer {
    display: flex;
    gap: 16px;
    place-items: center;
}
.buttonContainer > button {
    font-size: 14;
    padding: 6px 16px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--white);
    font-weight: 600;
    transition: all 0.5s ease;
    border-radius: 6px;
}
.buttonContainer > button:hover {
    opacity: 0.8;
}
.buttonContainer > button:first-child {
    background: var(--secondary);
}
.buttonContainer > button:last-child {
    background: var(--primary);
}
.headerContainer > h2 > span {
    font-size: 14px;
    color: var(--primary);
    font-weight: 600;
}
.table {
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    text-align: center;
    border-radius: 20px;
    box-shadow: 4px 8px 16px 0px #f0f0f0;
    font-size: 14px;
    color: var(--dark-grey);
    font-size: 400;
    width: 100%;
}

.thead > tr > th {
    border: 1px solid #dde0e5;
    padding: 21px 31px;
    background: #f1f1f2;
    color: #271810;
    font-weight: 500;
}
.thead > tr {
    overflow: hidden;
}
.thead > tr > th:first-child {
    border-radius: 10px 0 0 0;
}
.thead > tr > th:last-child {
    border-radius: 0 10px 0 0;
}

.tbody > tr > td {
    border: 1px solid #dde0e5;
    padding: 12px 30px;
}
.tbody > tr > td > span {
    font-weight: 600;
}

.tbody > tr:last-child > td:last-child {
    border-radius: 0 0 15px 0;
}
.lastRow {
    border-radius: 0px 0px 0px 15px;
}
.tbody > tr > td > input:disabled {
    border: none;
    background: none;
    max-width: 1.5rem;
    box-shadow: none;
    padding: 0;
}
.tbody > tr > td > input {
    background: none;
    padding: 6px 8px;
    box-shadow: inset 1px 2px 4px var(--grey2);
    font-size: 14px;
    color: var(--dark);
    max-width: 3.5rem;
    border-radius: 4px;
    border: none;
    text-align: center;
}
.tbody > tr > td > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.tbody > tr > td > span > input {
    background: none;
    padding: 6px 8px;
    box-shadow: inset 1px 2px 4px var(--grey2);
    font-size: 14px;
    color: var(--dark);
    border: none;
    max-width: 2.5rem;
    border-radius: 4px;
}
.tbody > tr > td > span > input {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
    text-align: center;
}
.tbody > tr > td > span > input:disabled {
    border: none;
    background: none;
    max-width: 1.5rem;
    font-weight: 600;
    box-shadow: none;
    padding: 0;
}
.tbody > tr > td > span > input[type='number']::-webkit-inner-spin-button,
.tbody > tr > td > span > input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.tbody > tr > td > input[type='number']::-webkit-inner-spin-button,
.tbody > tr > td > input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
