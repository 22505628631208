.content {
    margin-top: 40px;
    padding: 25px 3%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
}

.cardBox {
    margin-bottom: 10px;
    position: relative;
    background: var(--white);
    box-shadow: 3px 3px 15px var(--light-grey);
    border-radius: 7px;
    /* width: 14rem; */
    cursor: pointer;
    height: 100%;
}

.cardBox .wrap {
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 40px 25px 20px;
}

.cardBox .wrap:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    content: '';
}

.cardBox:nth-child(1) .wrap:after {
    background: linear-gradient(90deg, var(--primary) 0%, var(--faded-orange) 100%);
}
.cardBox:nth-child(2) .wrap:after {
    background: linear-gradient(90deg, var(--primary) 0%, var(--faded-orange) 100%);
}
.cardBox:nth-child(3) .wrap:after {
    background: linear-gradient(90deg, var(--primary) 0%, var(--faded-orange) 100%);
}
.cardBox:nth-child(4) .wrap:after {
    background: linear-gradient(90deg, var(--primary) 0%, var(--faded-orange) 100%);
}

.cardBox span {
    display: block;
}

.icon {
    position: absolute;
    z-index: 10;
    font-size: 1em;
    color: var(--white);
    transform: rotate(40deg);
    right: 22px;
    margin-top: 10px;
    opacity: 0.8;
    cursor: pointer;
}

.icon:hover {
    font-size: 1.5em;
    opacity: 0.9;
    transition: 300ms;
}

.title {
    color: var(--dark-grey);
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
}

.title > a {
    color: var(--dark-grey);
}

.cardBox:nth-child(5) .title,
.cardBox:nth-child(6) .title,
.cardBox:nth-child(7) .title {
    margin-top: 0px;
}

.count {
    font-weight: 600;
    font-size: 16px;
    line-height: 64px;
    color: var(--dark-grey);
    margin-top: -4px;
}

.count > a {
    color: var(--dark-grey);
}

.subInfo {
    color: var(--dark-grey);
    font-size: 12px;
    display: flex;
    justify-content: space-around;
}
