.wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0;
    background: rgb(0, 0, 0, 0.4);
}
.container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25em;
    padding: 30px;
    transform: translate(-50%, -50%);
    background: var(--white);
    box-sizing: border-box;
    box-shadow: 1px 1px var(--light);
    border-radius: 10px;
    margin: 0 auto;
}

.container > div > p {
    text-align: center;
}

.buttonContainer {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    gap: 4px;
}

.buttonContainer > button {
    height: 2rem;
    width: 4rem;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    color: var(--white);
}

.buttonContainer > button:hover {
    opacity: 0.8;
}

.buttonContainer > button:nth-child(1) {
    background-color: var(--green);
}
.buttonContainer > button:nth-child(2) {
    background-color: var(--red);
}
