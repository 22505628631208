.close {
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-bottom: 10px;
}
.close > button {
    display: flex;
    justify-content: center;
    place-items: center;
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: #f86809;
    background: #fff1e6;
}
.close > button:hover {
    opacity: 0.8;
}
.header {
    font-weight: 500;
    color: var(--dark-grey);
    margin: auto;
}
.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
    padding-bottom: 12px;
}
.innerContainer > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 450;
}
.innerContainer > input[type='text'] {
    border-radius: 4px;
    padding: 6px 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;
    padding-left: 12px;
    width: 100%;
}
.innerContainer > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.submitContainer {
    text-align: center;
    margin-top: 10px;
}
.submitContainer > button {
    border: none;
    padding: 6px 14px;
    background: var(--blue);
    color: var(--white);
    border-radius: 6px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 600;
    transition: all 1s ease;
}
.submitContainer > button:hover {
    opacity: 0.8;
}
.submitMessage {
    display: flex;
    justify-content: center;

    font-size: 13px;
    font-weight: 600;
    position: relative;
}
