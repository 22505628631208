.container,
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100vh;
}

.overlay {
    background: rgba(143, 118, 98, 0.5);
    z-index: 51;
}
.form {
    position: relative;
    z-index: 52;
    top: 50%;
    left: 50%;
    background: var(--white);
    transform: translate(-50%, -50%);

    padding: 1% 1%;
    border: 1px solid rgba(255, 210, 172, 1);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
}
/* rgb(38 22 10 / 50%) */
