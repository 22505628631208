.pageWrapper {
    width: 40%;
    margin: 10px auto;
    font-weight: 500;
}

.pageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
}
.page {
    display: flex;
    justify-content: center;
    color: var(--secondary);
}

.page > li {
    margin-left: 20px;
    cursor: pointer;
    font-size: 12px;
    padding: 4px 10px;
}

.page > li:last-child {
    margin-right: 10px;
}

.pageWrapper .btn {
    cursor: pointer;
    font-size: 12px;
    color: var(--primary);
    padding: 4px 10px;
}

.pageWrapper .btn:hover {
    font-size: 12px;
}

.pageWrapper .btnOff {
    cursor: default;
    font-size: 12px;
    color: var(--primary);
    padding: 4px 10px;
    opacity: 0.4;
}

.page .active {
    background-color: var(--secondary);
    color: var(--white);
    border-radius: 4px;
}

.pageShow {
    font-size: 12px;
    color: var(--black) !important;
    font-weight: 500;
    float: right;
    margin-right: 0.7rem;
    margin-left: 1rem;
    margin-top: 1rem;
}

.pageContainer > input {
    border: 1px var(--primary) solid;
    outline: none;
    border-radius: 0.3rem;
    padding: 0.2rem;
    width: 4rem;
    text-align: center;
}

@media (max-width: 420px) {
    .pageWrapper {
        margin: -10px auto;
        width: 80%;
    }

    .pageContainer {
        margin-top: 4px;
        padding: 8px 0px;
    }

    .pageWrapper .btn {
        font-size: 10px;
        padding: 4px 2px;
        margin: 0 10px;
    }

    .pageWrapper .btn:hover {
        font-size: 10px;
    }

    .pageWrapper .btnOff {
        font-size: 10px;
        padding: 4px 2px;
        margin: 0 10px;
    }

    .pageShow {
        font-size: 10px;
        margin-top: -16px;
    }
}
