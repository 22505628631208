.formBox {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0px #f2f2f2;
    padding: 16px 0;
    margin-bottom: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 10px;
    position: relative;
}

.formLayout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.title {
    font-size: 16px;
    line-height: 26px;
    color: var(--text-bold-color);
    padding-left: 20px;
    padding-right: 20px;
}
.spreadTitle > div {
    display: flex;
    align-items: center;
    gap: 20px;
}
.spreadTitle > div:nth-child(1) {
    gap: 10px;
}
.title > div svg path {
    fill: var(--primary);
}
.title.borderBottom {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f2f2f2;
}
.title p {
    color: var(--text-light);
    font-size: 12px;
    line-height: 18px;
    margin-top: 3px;
}

label {
    font-size: 13px;
    line-height: 23px;
    color: var(--text-bold-color);
}

sup {
    vertical-align: bottom;
    color: var(--primary);
    font-weight: 600;
    font-size: 14px;
}

.couriers {
    margin-top: 12px;
}

.partnerBox {
    padding: 5px 12px;
    font-size: 14px;
    border: 1px solid;
    border-color: var(--border-color);
    border-radius: 6px;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
}

.partnerBox:hover {
    border-color: var(--primary);
    transform: translateY(-2px);
    transition: all 0.4s;
}

.partnerBox.selected {
    border-color: var(--primary);
    background: #ffba9307;
}

.partnerBox > div:nth-child(1) {
    flex: 1;
}
.partnerBox > div:nth-child(1) > div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.partnerBox h4 {
    font-size: 13px;
    line-height: 23px;
    font-weight: 500;
    color: var(--text-bold-color);
}

.radioBtn > div {
    border: 1.5px solid #757575;
    width: 16px;
    height: 16px;
    background: transparent;
    border-radius: 50%;
}
.radioBtn > div > div {
    display: none;
}
.radioBtn > div.active {
    border: 1.5px solid var(--primary);
    position: relative;
}
.radioBtn > div.active > div {
    display: block;
    width: 100%;
    height: 100%;
    background: var(--primary);
    border-radius: 50%;
    border: 2px solid #fff;
}

.partnerBox > div:nth-child(2) {
    text-align: end;
}

.logo {
    width: 50%;
    object-fit: contain;
    height: auto;
    vertical-align: middle;
}

.partnerBox input {
    width: auto;
}

.quantity {
    position: relative;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    overflow: hidden;
}
.quantity.overflowVisible {
    overflow: visible;
}
.quantity.amount > input {
    text-align: left;
}
.quantity > div:not(.popup) {
    min-width: 60px;
    height: 40px;
    background: #f1f1f2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    top: 0;
    padding: 0 8px;
}
.quantity > input {
    border: none;
    color: #000;
    text-align: center;
}
.quantity .inputValue {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    background: transparent;
    color: #000;
    padding-left: 20px;
    justify-content: left;
    width: 100%;
}

.quantity > div:first-child {
    left: 0;
}
.quantity > div:last-child {
    right: 0;
    font-size: 13px;
}
.quantity.focus,
.quantity:focus-within {
    border: 1px solid var(--primary);
    box-shadow: 0 0 0 2px #ffe6d8;
}

.quantity.focus > div,
.quantity:focus-within > div {
    background: var(--pink);
}
.quantity.focus svg path,
.quantity:focus-within > svg path {
    fill: #000;
}
.warning {
    color: #ff0a0a;
    font-size: 13px;
    line-height: 23px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.warning svg path {
    fill: revert-layer;
}
.wrongInput {
    border: 1px solid red;
}
