.container {
    background: var(--white);
    border-radius: 10px;
}
.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 25px 30px;
}
.innerContainer > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 16px;
    margin-bottom: 3px;
    font-weight: 450;
}
.innerContainer > input[type='text'] {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;

    padding-left: 12px;
}
.innerContainer > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.half {
    display: grid;
    /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
    grid-template-columns: 35% 35% 25%;
    grid-gap: 30px;
    font-weight: 450;
    margin-bottom: 2em;
}
.half > div {
    width: 100%;
}
.half > div > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 16px;
    margin-bottom: 3px;
}
.half > div > input[type='text'] {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 16px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;

    padding-left: 12px;
}
.half > div > input[type='text']:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}

.buttonContainer {
    width: 100%;
    text-align: center;
}
.buttonContainer > button {
    margin: 10px 0px;
    border-radius: 6px;
    padding: 6px 13px;
    border: none;
    cursor: pointer;
    background: var(--blue);
    color: var(--white);
    font-weight: 600;
}
.buttonContainer > button:hover {
    opacity: 0.8;
}
.radio {
    display: flex;
    place-items: center;
    gap: 1.5em;
    row-gap: 0.8em;
    border: 1px solid var(--primary);
    padding: 8px;
    border-radius: 6px;
    flex-wrap: wrap;
    align-self: flex-start;
}
.bank {
    display: flex;
    place-items: center;
    gap: 1.5em;
    width: 100%;
}
.bankDetails {
    flex-basis: 100%;
}
.bankDetails > h2 {
    font-size: 18px;
    margin: 0px;
    color: black;
    font-weight: 400;
    margin-bottom: 1em;
    margin-left: 0.5em;
}
.bankDetails > p {
    font-size: 12px;
    margin: 0px;
    margin-left: 1em;
    margin-bottom: 10px;
}
input[type='radio'] {
    accent-color: #ff4500;
    outline: 1px auto #ff7f50;
}
.paymentMethod {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.hr {
    margin-bottom: 10px;
    height: 2px;
}
.selectContainer {
    margin-bottom: 2em;
    display: flex;
    gap: 1.5em;
    place-items: center;
    position: relative;
}
.selector {
    flex-grow: 1;
    font-size: 16px;
    border: 1px solid var(--primary);
    padding: 2px;
    padding-left: 6px;
    border-radius: 6px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    background-color: var(--white);
    outline: none;
}
.selector {
    outline: none;
    border: 1px solid var(--deep-primary);
}
.selectArrow {
    position: absolute;
    right: 10px;
    pointer-events: none;
    color: var(--deep-primary);
}

option {
    background-color: var(--white);
}
.payment {
    position: relative;
    margin: 1em;
}
.payment > p {
    position: absolute;
    top: 4px;
    left: 8px;
    font-size: 10px;
    color: var(--grey);
}
.payment > p:after {
    content: ' *';
    color: red;
}
.payment > input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    padding-top: 20px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 10px;
    min-height: 45px;
    color: var(--dark);
    border: none;

    padding-left: 12px;
    margin-left: auto;
}
.payment > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.payment > input:focus + p {
    font-size: 8px;
}
.payment:last-child {
    margin-bottom: 0.5em;
}
.bankInfo {
    background: var(--white);
    padding: 1em;
    border-radius: 6px;
    margin: 0.5em;
}
.infoField {
    position: relative;
    margin-bottom: 1em;
}
.infoField > p {
    font-size: 10px;
    color: var(--dark-grey);
    margin-top: 5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -30%;
    padding: 0px 5px;
    background: var(--white);
}
.infoField > div {
    width: 100%;
    display: flex;
    place-items: center;
    font-size: 12px;
    padding-left: 8px;
    min-height: 40px;
    border: 1px solid var(--primary);
    border-radius: 6px;
}
.infoField:last-child {
    margin-bottom: 0px;
}
