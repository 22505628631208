.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
    padding-bottom: 12px;
}
.innerContainer > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 450;
}
.innerContainer > input[type='text'] {
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;
    padding-left: 12px;
    width: 100%;
}
.innerContainer > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.submitContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.submitContainer > button {
    border: none;
    padding: 6px 14px;
    background: var(--primary);
    color: var(--white);
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: -33px;
}
.submitContainer > button:hover {
    opacity: 0.8;
}
.submitMessage {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    font-size: 13px;
    font-weight: 600;
    position: relative;
}
