.container {
    background: var(--white);
    border-radius: 10px;
}
.whiteContainer {
    padding: 20px;
}

.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
}
.add {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
}
.add > button {
    display: flex;
    border: none;
    background: var(--primary);
    color: var(--white);
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    gap: 5px;
}
.add > button:hover {
    opacity: 0.8;
}
.disabled {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
}
.disabled > button {
    display: flex;
    border: none;
    background: var(--primary);
    color: var(--white);
    padding: 8px 12px;
    border-radius: 4px;

    gap: 5px;
    opacity: 0.6;
}
