.container {
    width: 97%;
    padding-bottom: 30px;
    margin: 6% auto;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 3px 3px 15px var(--light-grey);
    margin-bottom: 20px;
}
.titleContainer {
    background: linear-gradient(90deg, var(--primary) 0%, var(--faded-orange) 100%);
    padding: 14px 30px 15px 10px;
    border-radius: 10px 10px 0 0;
    position: relative;
    text-align: center;
}
.titleContainer > h1 {
    color: var(--white);
    font-size: 16px;
    margin: 0px;
    font-weight: 500;
}
.icon {
    position: absolute;
    color: var(--white);
    font-size: 1em;
    transform: rotate(45deg);
    right: 2%;
    bottom: 17px;
}
.formContainer {
    padding: 30px;
}
