.container {
    margin-bottom: 270px;
}
.action {
    display: flex;
    justify-content: center;
    gap: 16px;
}
.action > button > svg {
    color: var(--primary);
    cursor: pointer;
    transition: all 0.2s ease;
}

.action > button:first-child > svg:hover {
    opacity: 0.8;
}
.action > button:last-child > svg {
    color: var(--deep-red) !important;
}
.action > button:last-child > svg:hover {
    opacity: 0.8;
}
.emptyWhite {
    background: var(--white);
    padding: 20px;
    border-radius: 0 0 6px 6px;
}
