@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&family=Roboto:wght@300;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins';
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --background: rgb(255, 255, 255);
    --deep-primary: #ff4500;
    --primary: #ff7f50;
    --secondary: #000080;
    --faded-orange: #ffa07a;
    --light-secondary: #3ecbcf;
    --light-secondary2: #2cbdb3;
    --dark: #333;
    --light-dark: rgb(222, 222, 222);
    --dark-blue: #23395d;
    --blue: #2b5199;
    --dark-grey: #555;
    --grey: #7987a1;
    --black: #000000;
    --grey2: #aebacf;
    --light-grey: #dee2e9;
    --light: #f0f0f0;
    --light-low: #f8f8f8;
    --light-low-2: #fafafa;
    --white: #fff;
    --green: #5cb484;
    --light-green: #7eb88b;
    --deep-red: #a10f0f;
    --red: #dd4545;
    --light-orange: #cbb059;
    --light-sky: #bddee4;
    --text-light: #555555;
    --pink: #ffe6d8;
    --light-pink: #f9f9f9;
    --border-color: #dde0e5;
    --text-bold-color: #271810;
    --placeholder-color: #828282;
    /* --light-sky: #c3e1e9; */
}
input {
    width: 100%;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb {
    background: rgb(171, 171, 171);
    border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
    background: rgb(151, 151, 151);
}

body {
    margin: 0;
    background-color: var(--light-low-2);
}
