.container {
    padding-bottom: 180px;
}
.container > h2 {
    text-align: center;
    font-size: 17px;
    margin: 0px;
    font-weight: 400;
    margin-bottom: 0.7%;
}
td > button {
    border: none;
    background: none;
    cursor: pointer;
}
td > button > svg {
    color: var(--primary);
}
.actionHeader {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.action {
    display: flex;
    place-items: center;
    justify-content: space-between;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

@media (max-width: 420px) {
    .list {
        padding: 14px 4px;
        padding-bottom: 0px;
    }

    .row td {
        display: flex;

        position: relative;
        border: 1px solid var(--white);
        font-size: 12px;
        justify-content: flex-end;
        padding-right: 8% !important;
    }

    .row td:before {
        position: absolute;
        top: 10px;
        left: 26px;

        white-space: nowrap;
        font-size: 12px;
        font-weight: 600;
    }

    .row td:nth-of-type(1):before {
        content: 'Serial';
    }
    .row td:nth-of-type(2):before {
        content: 'User Name';
    }
    .row td:nth-of-type(3):before {
        content: 'Phone';
    }
    .row td:nth-of-type(4):before {
        content: 'Bank Name';
    }

    .row td:nth-of-type(5):before {
        content: 'Loan Name';
    }
    .row td:nth-of-type(6):before {
        content: 'Amount';
    }

    .row td:nth-of-type(7):before {
        content: 'Created at';
    }
}
