.preview {
    display: flex;
    gap: 20px;
    align-items: start;
    margin-top: 18px;
}

input[type='file'] {
    width: 100%;
    display: none;
}
.previewBox {
    border: 2px dashed var(--border-color);
    width: 100%;
    border-radius: 6px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    overflow: hidden;
}
.previewBox.error {
    border: 2px dashed red;
}
.previewBox .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.previewBox .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.imageBox1 {
    width: 200px;
    text-align: center;
}

.imageBox1 .overlay,
.imageBox2 .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: #00000099;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    display: none;
    z-index: 1000;
    margin-top: 0;
}
.overlay svg path {
    fill: #fff;
}
.imageBox1:hover .overlay,
.imageBox2:hover .overlay {
    display: flex;
}

.imageBox1 .previewBox {
    height: 200px;
    position: relative;
}
.imageBox2 {
    display: flex;
    gap: 20px;
    text-align: center;
}

.warning {
    margin-top: 8px;
    color: #ff0a0a;
    font-size: 13px;
    line-height: 23px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.warning svg path {
    fill: revert-layer;
}
.preview label {
    display: inline-block;
    margin-top: 8px;
    font-size: 12px;
    line-height: 22px;
    color: var(--text-bold-color);
}
.preview label sup {
    vertical-align: bottom;
    color: var(--primary);
    font-weight: 600;
    font-size: 14px;
}

.imageBox2 .previewBox {
    width: 180px;
    height: 180px;
}
.imageBox1 svg {
    transform: scale(1.2);
}
.previewBox svg path {
    fill: revert-layer;
}

.cropContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background: #fff;
    padding: 20px;
    width: 50%;
    height: 50%;
    padding: 20px;
    border: 1px solid var(--primary) !important;
    box-shadow: 0 0 0 2px #ffe6d8;
    border-radius: 10px;
    overflow: hidden;
}
.cropContainer > div:nth-child(2) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    position: absolute;
    top: 88%;
    z-index: 1000;
    left: 20px;
    right: 20px;
}
.cropWrapper {
    position: absolute;
    top: 0;
    z-index: 100000;
    width: 100%;
    height: 85%;
    left: 0;
}
.slider {
    margin: 10px 0;
    height: 8px;
    background: #ddd;
    border-radius: 4px;
}

.thumb {
    height: 20px;
    width: 20px;
    background: var(--primary);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: -6px;
}

.track {
    background: var(--border-color);
    height: 100%;
    border-radius: 4px;
}
.actions {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: flex-end;
}
