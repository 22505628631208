.wrapper {
    margin-top: 40px;
    width: 95%;
    margin-left: 30px;
}

.txt {
    text-align: center;
    font-size: 17px;
    margin-bottom: 2px;
}

.icon {
    color: var(--primary);
}
