.sidebar {
    background-color: var(--background);
    height: 100vh;
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0 0.05rem 0.6rem 0.05rem rgba(121, 121, 121, 0.051);
    transition: width 0.3s linear;
}

.sidebar > div:nth-child(2) {
    margin-top: 16px;
}

.logo {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkItem {
    border-radius: 6px;
    position: relative;
}

.linkItem.active a {
    color: var(--blue);
    font-weight: 500;
}

/* open */

.sidebar.open {
    width: 255px;
}

.logo.open img {
    width: 154px;
    height: 22px;
}

.sidebar.open .linkItem {
    margin: 0 20px;
    width: auto;
    /* color: white; */
    overflow: hidden;
    margin-bottom: 5px;
}

/* close */

.sidebar.close {
    width: 90px;
}

.logo.close img {
    height: 43px;
}

.sidebar.close .linkItem {
    margin: 0 auto;
    width: fit-content;
    position: relative;
    display: block;
    margin-bottom: 10px;
}

/* .sidebar.close .dropDown .linkItem {
    margin-bottom: 0 !important;
}
.sidebar.open .dropDown .linkItem,
.sidebar.close .dropDown .linkItem a,
.sidebar.open .linkItem.dropdownList,
.sidebar.close .linkItem.dropdownList a {
    margin-bottom: 0;
} */

.sidebar.open .linkItem .tooltip {
    display: none;
}

.sidebar.close .linkItem .tooltip {
    position: absolute;
    display: block;
    font-size: 13px;
    line-height: 19px;
    background: var(--pink);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 8px 12px;
    border-radius: 6px;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    width: max-content;
    display: block;
}

.sidebar.close .linkItem svg {
    transform: scale(1.1);
}
.sidebar .linkItem a {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 18px;
    color: var(--text-light);
}
.sidebar.close .linkItem a {
    width: fit-content;
}
.sidebar .linkItem.dropdownList a {
    justify-content: space-between;
}

.sidebar.open .linkItem.dropdownList a > div:nth-child(1) {
    display: flex;
    gap: 12px;
    align-items: center;
}
.sidebar.close .linkItem.dropdownList a > div:nth-child(1) {
    display: inline-flex;
}

.sidebar .linkItem.active {
    background-color: var(--pink);
}
.sidebar .dropDown .linkItem.active {
    background-color: #fff;
}
.sidebar .linkItem a {
    color: var(--text-light);
}
.sidebar .linkItem a svg path {
    fill: var(--text-light);
}
.sidebar .linkItem:hover a,
.sidebar .linkItem:hover svg path,
.sidebar .linkItem.active a,
.sidebar .linkItem.active svg path {
    color: var(--blue);
    fill: var(--blue);
}
.sidebar .linkItem.pause a {
    border-radius: 6px;
    background: var(--light-pink) !important;
}
.sidebar .dropDown .linkItem:hover a,
.sidebar .dropDown .linkItem:hover svg path,
.sidebar .dropDown .linkItem.active a,
.sidebar .dropDown .linkItem.active svg path {
    color: var(--primary);
    fill: var(--primary);
    background: #fff;
}
.sidebar .dropDown.open .linkItem > div:nth-child(1) {
    margin-left: 10px;
    border-left: 2px solid var(--pink);
}
.sidebar .dropDown.pause .linkItem > div:nth-child(1) {
    border-left: 2px solid var(--light-pink) !important;
}
.sidebar.open .dropDown .linkItem a {
    padding: 8px 18px 3px 18px;
}
.sidebar.close .dropDown .linkItem a {
    padding: 10px 18px;
}

.iconDropDown {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
}

.iconUpperDown {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.dropDown {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    transform: scaleY(0);
    height: 0;
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
}

.dropDown.open {
    height: fit-content;
    transform: scaleY(1);
    transition: transform 0.3s ease-in-out;
}

.dropDown .linkItem {
    margin-bottom: 0 !important;
}
/* .dropdownList.active {
    margin-bottom: 0 !important;
} */
.sidebar .dropdownList.active {
    margin-bottom: 0 !important;
}
/* .sidebar.close .submenu {
    padding: 10px;
} */
