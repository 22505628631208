.tableContainer {
    background: #fff;
    border-top: 1px solid #f2f2f2;
    margin-top: 7px;
}
.tableWrapper {
    max-width: 1000px;
    margin: 0 20px;
    margin-top: 12px;
}

h3 {
    font-size: 16px;
    line-height: 26px;
    color: var(--text-bold-color);
}
h3 span {
    font-weight: 500;
    color: var(--primary);
}
.table {
    margin-top: 12px;
    width: 100%;
    text-align: left;
    font-family: Arial, sans-serif;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    overflow: hidden;
}

.table th,
.table td {
    padding: 12px 15px;
    border: 1px solid #f1f1f1;
    font-size: 14px;
    line-height: 21px;
    color: var(--text-bold-color);
}

.table th {
    background-color: var(--light-pink);
    font-weight: 500;
    text-align: center;
}

.table td {
    color: var(--text-bold-color);
}

.area {
    text-align: center;
    vertical-align: middle;
}
