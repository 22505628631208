.container {
    margin-bottom: 200px;
}
.container > h2 {
    text-align: center;
    font-size: 17px;
    margin: 0px;
    font-weight: 400;
    margin-bottom: 0.7%;
}
.actionHeader {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.emptyWhite {
    background: var(--white);
    padding: 20px;
    border-radius: 0 0 6px 6px;
}
@media (max-width: 420px) {
    .list {
        padding: 14px 4px;
        padding-bottom: 0px;
    }

    .row td {
        display: flex;

        position: relative;
        border: 1px solid var(--white);
        font-size: 12px;
        justify-content: flex-end;
        padding-right: 8% !important;
    }

    .row td:before {
        position: absolute;
        top: 10px;
        left: 26px;

        white-space: nowrap;
        font-size: 12px;
        font-weight: 600;
    }

    .row td:nth-of-type(1):before {
        content: 'Serial';
    }
    .row td:nth-of-type(2):before {
        content: 'Date';
    }
    .row td:nth-of-type(3):before {
        content: 'Vendor';
    }
    .row td:nth-of-type(4):before {
        content: 'Product';
    }

    .row td:nth-of-type(5):before {
        content: 'Quantity';
    }
    .row td:nth-of-type(6):before {
        content: 'Attribute';
    }

    .row td:nth-of-type(7):before {
        content: 'Price';
    }
    .row td:nth-of-type(8):before {
        content: 'Payment';
    }
    .row td:nth-of-type(9):before {
        content: 'Order';
    }
    .row td:nth-of-type(10):before {
        content: 'Action';
    }
}
