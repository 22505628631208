.wrapper {
    width: 100%;
    padding-bottom: 180px;
}

.txt {
    text-align: center;
    font-size: 17px;
    margin-bottom: 2px;
}

.icon {
    color: var(--primary);
}

.createSeller {
    display: flex;
    justify-content: flex-end;
}

.createSeller > button {
    border: none;
    outline: none;
    background-color: var(--faded-orange);
    padding: 6px 14px;
    border-radius: 4px;
    color: var(--white);
    cursor: pointer;
}
