.wrapper {
    width: 100%;
    padding-top: 6px;
}

.txt {
    font-size: 17px;

    margin: 0.5em 0em;
}

.icon {
    color: var(--primary);
}

.logo {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: 48% 16%;
    border-radius: 4px;
}

.EditButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
}

.save {
    border-radius: 0.2rem;
    background-color: var(--blue);
    padding: 0.3rem 0.8rem;
    color: white;
    cursor: pointer;
}

.save:hover {
    background-color: rgba(56, 76, 206, 0.935);
}

.cancel:hover {
    background-color: rgb(223, 65, 65);
}

.cancel {
    border-radius: 0.2rem;
    background-color: var(--deep-red);
    padding: 0.3rem 0.8rem;
    color: white;
    cursor: pointer;
}

.categoryInput {
    border: 1px grey solid;
    border-radius: 0.2rem;
    outline: none;
    padding: 0.5rem;
}

.iconDelete {
    color: var(--deep-red);
}

.iconDelete:hover {
    color: rgb(223, 31, 31);
}
.emptyWhite {
    background: var(--white);
    padding: 20px;
    border-radius: 0 0 6px 6px;
}
