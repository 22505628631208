.customDropDown :global(.react-select__control) {
    overflow: hidden;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 13px;
    line-height: 23px;
}
.customDropDown.error :global(.react-select__control) {
    border: 1px solid red;
}

.customDropDown :global(.react-select__indicators) {
    background: #f1f1f2;
    width: 50px;
    border-left: 1px solid;
    border-color: var(--border-color);
}

.customDropDown :global(.react-select__indicator-separator) {
    display: none;
}

.customDropDown :global(.react-select__indicator) {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.customDropDown svg path {
    fill: #757575;
}

.customDropDown :global(.react-select__placeholder) {
    font-size: 13px;
    line-height: 23px;
    color: var(--placeholder-color);
}

.customDropDown :global(.react-select__value-container) {
    padding: 0 20px;
}

.customDropDown :global(.react-select__input-container) {
    padding: 8px 0;
    margin: 0;
}
.customDropDown :global(.react-select__menu-list) {
    padding: 10px;
}

.customDropDown :global(.react-select__option) {
    font-size: 13px;
    line-height: 23px;
}

.customDropDown :global(.react-select__menu-notice) {
    font-size: 12px;
    line-height: 21px;
    color: #757575;
    padding: 0;
}

.customDropDown :global(.react-select__option--is-focused),
.customDropDown :global(.react-select__option--is-selected) {
    background: var(--pink);
    color: #000;
    border-radius: 6px;
}
.customDropDown :global(.react-select__option--is-selected) {
    margin: 4px 0;
}

/* focus */

.customDropDown :global(.react-select__control--is-focused) {
    border: 1px solid var(--primary) !important;
    box-shadow: 0 0 0 2px #ffe6d8;
    outline: none;
}
.customDropDown :global(.react-select__control--is-focused) :global(.react-select__indicators) {
    background: var(--pink) !important;
}
.customDropDown :global(.react-select__control--is-focused) svg path {
    fill: #000;
}

/* scrollbar */

.customDropDown :global(.react-select__menu-list)::-webkit-scrollbar {
    width: 5px;
}

.customDropDown :global(.react-select__menu-list)::-webkit-scrollbar-track {
    background: #f1f1f2;
    border-radius: 0px;
}

.customDropDown :global(.react-select__menu-list)::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
}

.customDropDown :global(.react-select__menu-list)::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
}
