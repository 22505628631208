.closeIcon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.closeIcon > p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: #f86809;
    background: #fff1e6;
}
.closeIcon > button:hover {
    opacity: 0.8;
}
.header p {
    font-weight: 500;
    color: var(--primary);
    text-align: center;
    margin-top: -20px;
    margin-bottom: 25px;
}
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
}
/* .activeBtnTrue {
    padding: 8px 20px;
    background-color: var(--green);
    border: 2px solid var(--green);
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.activeBtnFalse {
    padding: 8px 20px;
    border: 2px solid var(--green);
    color: var(--green);
    border-radius: 5px;
    cursor: pointer;
}
.inactiveBtnTrue {
    padding: 8px 20px;
    background-color: var(--red);
    border: 2px solid var(--red);
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.inactiveBtnFalse {
    padding: 8px 20px;
    border: 2px solid var(--red);
    color: var(--red);
    border-radius: 5px;
    cursor: pointer;
} */

.activebtn {
    padding: 8px 20px;
    background-color: var(--green);
    border: 2px solid var(--green);
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.cancelbtn {
    padding: 8px 20px;
    background-color: var(--red);
    border: 2px solid var(--red);
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
