.container {
    background: #e4effc;
    padding: 5px 10px;
    padding-top: 7px;
    display: inline-block;
    color: var(--dark);
    font-weight: 500;
    margin: 0px;
    font-size: 12px;
    line-height: 13.8px;
    letter-spacing: 0.03em;
    margin-right: 5px;
    flex-shrink: 0;
}
