.container {
    display: flex;
    place-items: center;
    justify-content: space-around;
    position: relative;
    width: 100%;
}
.container > button {
    border: none;
    background: none;
    cursor: pointer;
    color: var(--primary);
}
.status {
    position: absolute;
    width: 100px;
    background: var(--white);
    box-shadow: var(--light) 0px 1px 4px;
    top: 2em;
    right: 1.5em;
    /* padding: 5px; */
    z-index: 500;
    border-radius: 4px;
}
.status > button {
    width: 100%;
    border: none;
    background: none;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
    cursor: pointer;
}

.approve {
    color: var(--secondary);
    margin-bottom: 5px;
}
.reject {
    color: var(--red);
}
.approve:hover {
    background: var(--secondary);
    color: var(--white);
    opacity: 0.7;
    border-radius: 4px 4px 0px 0px;
}
.reject:hover {
    background: var(--red);
    color: var(--white);
    opacity: 0.7;
    border-radius: 0px 0px 4px 4px;
}
@media (max-width: 420px) {
    .container {
        justify-content: flex-end;
        gap: 10px;
    }
}
