.container {
    margin-bottom: 25px;
}
.productBorder {
    border: 0.5px solid var(--faded-orange);
    border-radius: 6px;
    padding: 30px 20px;
    margin-top: 15px;
}
.productSearch {
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-bottom: 20px;
}
.search {
    display: flex;
    place-items: center;
    gap: 30px;
}
.id {
    font-size: 14px;
    font-weight: 600;
}

.container > div {
    width: 100%;
}

.productSelect {
    display: flex;
    gap: 10px;
}
.productSelect > input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;

    color: var(--dark);
    border: none;

    padding-left: 12px;
}
.productSelect > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}

.productSelect > div > button {
    display: flex;
    border: none;
    background: var(--primary);
    color: var(--white);
    place-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.productSelect > div > button:hover {
    opacity: 0.8;
}
.ProductName {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 30px;
}
.ProductName > div > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 450;
}
.ProductName > div > div {
    width: 100%;
    background: var(--white);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    cursor: default;
    min-height: 37px;
}
.minusContainer {
    justify-self: end;
    align-self: center;
}
.minusContainer > button {
    display: flex;
    border: none;
    background: var(--primary);
    color: var(--white);
    place-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.minusContainer > button:hover {
    opacity: 0.8;
}
.productDetails {
    display: flex;
    justify-content: center;
    gap: 40px;
}
.productDetails > div > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 450;
}
.productDetails > div > input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;

    color: var(--dark);
    border: none;

    padding-left: 12px;
}
.productDetails > div > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.error {
    text-align: center;
    margin-bottom: 1em;
    font-weight: 600;
    color: rgb(225 29 72);
}
