.container {
    background: var(--white);
    border-radius: 10px;
}
.whiteContainer {
    padding: 20px;
}

.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
}
.innerContainer > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 3px;
    font-weight: 450;
}
.innerContainer > input[type='text'] {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;

    padding-left: 12px;
}
.half {
    display: grid;
    /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
    grid-template-columns: 35% 35% 25%;
    grid-gap: 30px;
    font-weight: 450;
    font-size: 14px;
    grid-auto-rows: min-content;
    grid-auto-flow: dense;
    margin-bottom: 3em;
}
.half > div {
    width: 100%;
}

.radio {
    display: flex;
    place-items: center;
    gap: 1.5em;
    row-gap: 0.8em;
    border: 1px solid var(--primary);
    padding: 8px;
    border-radius: 6px;
    flex-wrap: wrap;
    align-self: flex-start;
    overflow: hidden;
}
.bank {
    display: flex;
    place-items: center;
    gap: 1.5em;
    width: 100%;
    cursor: pointer;
}
.bankDetails {
    flex-basis: 100%;
}
.bankDetails > h2 {
    font-size: 14px;
    margin: 0px;
    margin-left: 0.5em;
    color: black;
    font-weight: 400;
    margin-bottom: 2em;
}
.bankDetails > p {
    font-size: 12px;
    margin: 0px;
    margin-left: 1em;
    margin-bottom: 10px;
}
input[type='radio'] {
    accent-color: #ff4500;
    outline: 1px auto #ff7f50;
}
.paymentMethod {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.hr {
    margin-bottom: 10px;
    height: 2px;
}
.selectContainer {
    margin-bottom: 3em;
    display: flex;
    gap: 1.5em;
    place-items: center;
    position: relative;
}
.selectContainer > p {
    font-size: 14px;
    font-weight: 450;
}
.selector {
    flex-grow: 1;
    font-size: 14px;
    border: 1px solid var(--primary);
    padding: 5px;
    padding-left: 6px;
    border-radius: 6px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    background-color: var(--white);
    outline: none;
}
.selector {
    outline: none;
    border: 1px solid var(--deep-primary);
}
.selectArrow {
    position: absolute;
    right: 10px;
    pointer-events: none;
    color: var(--deep-primary);
}

option {
    background-color: var(--white);
}
.payment {
    width: 100%;
    display: flex;
    gap: 1em;

    place-items: center;
    white-space: nowrap;
    padding: 0.5em;
}
.payment > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 3px;
}
.payment > p:after {
    content: ' *';
    color: red;
}
.payment > input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    width: 50%;
    color: var(--dark);
    border: none;

    padding-left: 12px;
    margin-left: auto;
}
.payment > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.bankInfo {
    background: var(--white);
    padding: 1em;
    border-radius: 6px;
    margin: 2em;
}
.infoField {
    display: flex;
    gap: 2em;
    row-gap: 0.5em;
    place-items: center;
    margin-top: 5px;
}
.infoField:first-child {
    margin-top: 0px;
}
.infoField > p {
    font-size: 14px;
    color: var(--dark-grey);
}
.infoField > div {
    width: 50%;
    margin-left: auto;
    font-size: 14px;
}
