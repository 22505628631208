.wrapper > div:nth-child(2) {
    color: var(--blue);
    font-weight: 600;
    font-size: 0.8rem;
    margin: 1rem 0 0.5rem 0;
}

.wrapper > div:nth-child(3) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.noRfq {
    font-size: 0.9rem;
    color: var(--grey);
}

.rfq {
    box-shadow: 1px 2px 5px 3px rgba(142, 142, 142, 0.204);
    background-color: #fff;
    border-radius: 0.3rem;
    padding: 1.5rem 1rem 1rem 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 0.7rem;
    color: rgb(38, 38, 38);
    position: relative;
    overflow: hidden;
}

.sl {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary);
    color: white;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    font-size: 0.6rem;
    overflow: hidden;
    border-radius: 0 0 0.3rem 0;
}

.rfq > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
}

.gridWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.gridWrap > div {
    display: flex;
    flex-direction: column;
}

span {
    font-weight: 600;
    font-size: 0.8rem;
    color: black;
    gap: 0;
}

.productImage {
    width: 10rem;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productImage > img {
    max-width: 100%;
    max-height: 100%;
}

.fileIcon {
    font-size: 5rem;
}

@media (max-width: 430px) {
    .gridWrap {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .productImage {
        width: 100%;
    }
}
