.customEditor {
    padding: 0 20px;
    margin-top: 12px;
    position: relative;
}
.customEditor :global(.quill) {
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid;
    border-color: var(--border-color);
}
.customEditor :global(.ql-toolbar) {
    border: none;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    gap: 20px;
    padding: 0 20px;
    height: 40px;
    padding-left: 8px;
}
.customEditor :global(.ql-container) {
    border: none;
}

.customEditor :global(.ql-editor) {
    min-height: 280px;
    max-height: 400px;
    font-size: 13px;
    line-height: 23px;
    color: #333333;
}
.customEditor :global(.ql-editor) strong {
    font-weight: bold !important;
}
.customEditor :global(.ql-editor) img {
    max-height: 400px;
    object-fit: contain;
    width: 50%;
    display: block;
}
.customEditor :global(.ql-active) {
    background: var(--pink) !important;

    display: flex !important;
    align-items: center;
    border-radius: 4px;
    color: #000 !important;
}

.customEditor :global(.strokeIcon) svg path {
    stroke: transparent;
}
.customEditor :global(.unorderListIcon) svg path {
    fill: transparent;
}
.customEditor :global(.ql-snow.ql-toolbar button) {
    width: auto;
    padding: 0;
}
.customEditor :global(.ql-formats) {
    margin-right: 0 !important;
    display: flex;
    gap: 4px;
    position: relative;
    height: 100%;
    align-items: center;
}
.customEditor :global(.ql-formats button) {
    width: 30px !important;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.customEditor :global(.ql-formats:not(:last-child))::after {
    content: '';
    position: absolute;
    height: 100%;
    right: -10px;
    border: 1px solid var(--border-color);
}
.customEditor svg {
    height: 22px !important;
    line-height: 32px;
}
.customEditor :global(.ql-stroke) {
    stroke-width: 1px;
}
.customImageLimit {
    position: absolute;
    top: 7px;
    right: 35px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    color: var(--text-bold-color);
}
.customImageLimit strong {
    color: var(--primary);
    font-weight: 600;
    text-decoration: underline;
}
.customEditor :global(.ql-blank)::before {
    content: 'Write Down Product Description.......';
    font-size: 13px;
    line-height: 23px;
    color: var(--placeholder-color);
    font-style: normal;
}

input[type='file'] {
    width: 100%;
    display: none;
}
