.popupDetails {
    padding: 0 1em;
}
.closeIcon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.closeIcon > p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: #f86809;
    background: #fff1e6;
}
.closeIcon > button:hover {
    opacity: 0.8;
}
.header p {
    font-size: 19px;
    font-weight: 500;
    color: var(--primary);
    margin-top: -20px;
    margin-bottom: 10px;
    text-align: center;
}
.productDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.productTitle {
    display: flex;
    gap: 20px;
}
.img {
    width: 60px;
    height: 60px;
}
.porductImage {
    width: 100%;
    width: 100%;
    border-radius: 7px;
}
.title {
    font-size: 16px;
    font-weight: 500;
    color: var(--dark);
    text-transform: capitalize;
}
.pageName {
    font-size: 13px;
    color: var(--dark-grey);
    width: max-content;
}

.stock {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 20px 0;
    padding: 1.2em;
    background-color: #f7f8f8;
    border-radius: 10px;
}
