.wrapper {
    width: 100%;
    margin-bottom: 270px;
}

.filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1.2rem;
    margin-bottom: 0.5rem;
}

.filter > div {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    font-size: 0.9rem;
}

input[type='checkbox'] {
    accent-color: var(--primary);
    cursor: pointer;
}

.active {
    color: rgb(83, 83, 83);
}

.inactive {
    color: rgb(83, 83, 83);
}

.txt {
    text-align: center;
    font-size: 17px;
    margin-bottom: 0.7%;
}

.icon {
    color: var(--primary);
}

.logo {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: 48% 16%;
    border-radius: 4px;
}
