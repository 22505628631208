.wrapper {
    background: var(--white);
    padding: 1.5em 2em;
    border-radius: 8px;
    box-shadow: 3px 3px 15px var(--light-grey);
}
.tabSection {
    border-bottom: 1px solid var(--light-grey);
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    gap: 1.5em;
    position: relative;
}
.tab {
    padding: 12px 2px;
    cursor: pointer;
    font-weight: 450;
    font-size: 12px;
}
.active {
    padding: 12px 2px;
    border-bottom: 3px solid var(--primary);
    margin-bottom: -2px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    color: var(--primary);
}
.content {
    margin-top: 1em;
}
