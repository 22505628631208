.productCell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.productImage {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
}

.productTitle {
    font-size: 14px;
    font-weight: 400;
    color: #4b5563;
    word-wrap: break-word;
    white-space: normal;
    text-transform: capitalize;
    display: inline-block;
}
.tableRow {
    text-align: left;
}
.productShop {
    text-transform: capitalize;
}
/* .tableData td {
    text-align: left !important;
    width: 200px !important;
} */

.status {
    display: inline-flex;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
}
.active {
    background-color: #dcfce7;
    color: #166534;
}

.pending {
    background-color: #fef3c7;
    color: #92400e;
}
.viewButton1 {
    color: var(--green) !important;
}
.viewButton2 {
    color: var(--primary) !important;
}
.tableNotFound {
    height: 400px;
    text-align: center;
}
.tableNotFound > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}
