.layout {
    display: flex;
    flex-direction: row;
    background-color: var(--light-pink);
    position: relative;
}

.layout > div:nth-child(2) {
    flex: 1;
    overflow: auto;
}
.content {
    padding: 1em;
}
