.model,
.overlay {
    border: none;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 107;
    cursor: default;
}

.overlay {
    background-color: rgba(112, 112, 112, 0.543);
}
