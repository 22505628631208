* {
    box-sizing: border-box;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('../../../assets/Wallpaper/wallPaper4.jpg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logoConainer {
    margin-bottom: 2rem;
}

.container {
    background-color: var(--white);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    width: 500px;
    max-width: 100%;
    min-height: 520px;
}

.formContainer {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.signIn {
    left: 0;
    width: 100%;
    z-index: 2;
}

.container .formContainer p {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: var(--primary);
}

.container .formContainer span {
    font-size: 12px;
}

.container a {
    color: var(--dark);
    font-size: 12px;
    text-decoration: none;
    margin: 0px 0;
}

.container button {
    border-radius: 20px;
    border: 1px solid var(--secondary);
    background-color: var(--primary);
    color: var(--white);
    font-size: 12px;
    font-weight: 700;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 100ms;
    cursor: pointer;
    margin-top: 20px;
    border: none;
}

.container button:hover {
    opacity: 0.9;
}

.container button:active {
    transform: scale(0.95);
}

.container button:focus {
    outline: none;
}

.container .formContainer form {
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
    text-align: center;
}

.container .formContainer input {
    border: none;
    padding: 4px;
    margin: 12px 0;
    width: 100%;
    outline: none;
    border-bottom: 1px solid var(--grey2);
}

.container button.btn {
    background-color: transparent;
    border-color: var(--white);
}

.container button.btn:hover {
    opacity: 0.8;
}

.container p {
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 6px 0 30px;
}

.socialGroup {
    margin-bottom: 20px;
}

.socialGroup a {
    border: 2px solid var(--light);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

.overlayContainer {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.overlay {
    background: linear-gradient(to right, var(--primary), var(--secondary));
    color: var(--white);
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transition: transform 0.6s ease-in-out;
}

.overlayPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    height: 100%;
    width: 50%;
    transition: transform 0.6s ease-in-out;
}

.overlayRight {
    right: 0;
}

.wrapper .logo {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 24px;
    color: var(--white);
    font-weight: 620;
    margin: 34px 30px;
    z-index: 100;
}

.wrapper .logo:hover {
    opacity: 0.9;
    font-size: 26px;
    transition: 0.5s;
    color: var(--primary);
}

.logo .leaf {
    position: absolute;
    color: var(--primary);
    font-size: 14px;
    margin-top: -6px;
    margin-left: 16px;
}

.container .iconEye {
    position: absolute;
    font-size: 10px;
    text-decoration: none;
    padding: 4px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--dark-grey);
    margin-top: 2.6em;
    margin-left: 28.2em;
    opacity: 0.8;
}

.container .iconEye:hover {
    color: var(--secondary);
}

.alertMessage {
    position: absolute;
    z-index: 10;
    bottom: 8em;
    left: 8.2em;
    color: var(--red);
}

@media (max-width: 420px) {
    .wrapper {
        height: 100vh;
    }
    .overlayContainer {
        position: absolute;
        display: none;
    }

    .container {
        min-height: 500px;
    }

    .container .formContainer {
        width: 100%;
    }

    .container button {
        font-size: 12px;
        font-weight: 600;
        padding: 10px 30px;
        margin-top: 30px;
    }

    .container .formContainer p {
        color: var(--dark);
    }

    .wrapper .logo {
        display: none;
    }

    .container .formContainer::before {
        position: absolute;
        content: '';

        color: var(--secondary);
        margin: 24px;
        padding-top: 10px;

        height: 20px;
        width: 300px;
        /* background: url(../../../assets/logo.png) no-repeat; */
        position: absolute;
        background-size: 110px;
        margin-top: 32px;
    }

    .container .iconEye {
        margin-top: 2em;
        margin-left: 30em;
    }

    .alertMessage {
        bottom: 4em;
        left: 8em;
    }
}

@media (max-width: 410px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 29em;
    }
}

@media (max-width: 400px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 28em;
    }
}

@media (max-width: 390px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 27em;
    }
}

@media (max-width: 380px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 26em;
    }
}

@media (max-width: 370px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 25em;
    }
    .alertMessage {
        bottom: 4em;
        left: 7.6em;
    }
}

@media (max-width: 356px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 24em;
    }
    .alertMessage {
        bottom: 4em;
        left: 6em;
    }
}

@media (max-width: 340px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 22em;
    }
    .alertMessage {
        bottom: 4em;
        left: 5.4em;
    }
}

@media (max-width: 320px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 21em;
    }
}

@media (max-width: 310px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 20em;
    }
    .alertMessage {
        bottom: 4em;
        left: 5.2em;
    }
}

@media (max-width: 300px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 19em;
    }
}

@media (max-width: 290px) {
    .container .iconEye {
        margin-top: 2em;
        margin-left: 18em;
    }
}
