.container {
    background: var(--white);
    border-radius: 10px;
}
.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
}
.buttonContainer {
    width: 100%;
    text-align: center;
}
.buttonContainer > button {
    margin: 10px 0px;
    border-radius: 6px;
    padding: 6px 13px;
    border: none;
    cursor: pointer;
    background: var(--blue);
    color: var(--white);
    font-weight: 600;
}
.buttonContainer > button:hover {
    opacity: 0.8;
}
.add {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
}
.add > button {
    display: flex;
    border: none;
    background: var(--primary);
    color: var(--white);
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    gap: 5px;
}
.add > button:hover {
    opacity: 0.8;
}
.disabled {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
}
.disabled > button {
    display: flex;
    border: none;
    background: var(--primary);
    color: var(--white);
    padding: 8px 12px;
    border-radius: 4px;

    gap: 5px;
    opacity: 0.6;
}
