.wrapper {
    width: 100%;
    padding-bottom: 180px;
}

.filter {
    column-gap: 2em;
    row-gap: 0.5em;
    margin: 1em 0;
    cursor: default;
    flex-wrap: wrap;
    background-color: var(--white);
    padding: 1em;
    border-radius: 5px;
}

.filter > div {
    /* display: inline-flex; */
    /* flex-direction: row; */
    /* align-items: center; */
    /* gap: 0.3rem; */
    font-size: 0.7rem;
}
.filterTitle {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 10px;
    color: var(--text-light);
}
.titleIcon {
    font-size: 22px;
    margin-bottom: -6px;
}
.filterTitle > p {
    font-size: 14px;
}
.inputField {
    display: flex;
    align-items: center;
    gap: 20px;
    /* justify-content: space-between; */
}
.search {
    width: 30%;
    display: flex;
    align-items: center;
}
.search > input {
    width: 100%;
    border: 1px rgb(177, 177, 177) solid;
    padding: 7px 10px;
    border-radius: 0.3rem;
    outline: none;
}
.search > input::placeholder {
    font-size: 11px;
    font-weight: 500;
}
input[type='checkbox'] {
    accent-color: var(--primary);
    cursor: pointer;
}
.search > input:focus {
    border: 1px solid var(--primary);
}
.phoneSearch {
    margin-left: 1em;
}
.active,
.inactive {
    color: rgb(83, 83, 83);
    display: flex;
    gap: 5px;
}

.txt {
    text-align: center;
    font-size: 17px;
    margin-bottom: 2px;
}

.icon {
    color: var(--primary);
}

.logo {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: 48% 16%;
    border-radius: 4px;
}

.action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.iconEdit {
    color: var(--blue);
}

.exportCsv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    color: var(--dark-grey);
}

.csvIcon {
    font-size: 1rem;
    margin-top: -0.3rem;
}

.exportCsv:hover {
    color: var(--blue);
}

#subscription,
#verified {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    gap: 0.2rem;
}

.iconVerified {
    color: green;
    font-size: 0.9rem;
}

.iconFeatured {
    color: var(--secondary);
    font-size: 0.7rem;
}
.dateWrapper {
    width: 30%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.datePicker {
    border-radius: 0.3rem;
    border: 1px rgb(177, 177, 177) solid;
    width: 100%;
    margin-right: 0.5em;
    padding: 6px 25px !important;
}
/* .dateWrapper svg {
    padding: 0.6rem !important;
} */
.datePicker:focus {
    border: 1px solid var(--primary);
    outline: none;
}
.dateWrapper > button {
    display: flex;
    place-items: center;
    border: none;
    background: none;
    color: var(--deep-red);
    margin-left: 0.5em;
    cursor: pointer;
}
@media (max-width: 1550px) {
    .dateWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-right: 3em;
        margin-top: 0.5em;
    }
}
