/* Popup Form */
.containerForm,
.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    left: 0;
    top: 0;
}

.overlay {
    background: rgb(0, 0, 0, 0.5);
    z-index: 1;
}

.formWrapper {
    position: absolute;
    top: 50%;
    left: 55%;
    width: 76em;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: var(--white);
    box-sizing: border-box;
    box-shadow: 1px 1px var(--light);
    border-radius: 10px;
    margin: 0 auto;
    z-index: 10;
}

.formWrapper .buttonContainer {
    display: flex;
    justify-content: flex-end;
}

.formWrapper .close {
    position: absolute;
    top: 10px;
    right: 20px;
    transition: all 200ms;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: var(--red);
    cursor: pointer;
}
.formWrapper .close:hover {
    opacity: 0.8;
    transition: 0.5s;
}

.formWrapper > p {
    text-align: center;
    margin: 0;
    margin-top: -12px;
    padding-bottom: 20px;
    font-size: 18px;
}

.formWrapper > p > span {
    font-size: 20px;
    color: var(--secondary);
    font-weight: 500;
    padding: 0 2px;
}

/* collapsible */
.formWrapper .innerWrap {
    padding: 10px 20px;
    background: var(--light-low);
    border-radius: 6px;
    margin-bottom: 20px;
    height: 28em;
    overflow-y: auto;
}

.container {
    margin: 10px auto;
    width: 100%;
}

.container ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.container .labelMain {
    display: block;
    cursor: pointer;
    padding: 20px 30px;
    background-color: var(--white);
    box-shadow: 1px 2px 12px var(--light-grey);
    border-radius: 6px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-grey);
}

.container .labelMain:hover {
    background: var(--faded-orange);
    color: var(--white);
    opacity: 0.8;
}

.container input[type='checkbox'] {
    position: absolute;
    left: -9999px;
}

.container input[type='checkbox'] ~ ul {
    height: 0;
    transform: scaleY(0);
}

.container input[type='checkbox']:checked ~ ul {
    height: 100%;
    transform-origin: top;
    transition: transform 0.3s ease-out;
    transform: scaleY(1);
}

.container input[type='checkbox']:checked + label {
    background-color: var(--faded-orange);
    border-bottom: 1px solid var(--white);
    color: var(--white);
}

.container .buttonSubmit {
    background-color: var(--faded-orange);
    padding: 10px 30px;
    color: var(--white);
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin: 0 auto;
    letter-spacing: 1px;
    border: none;
    border-radius: 4px;
    display: block;
    margin-top: 2em;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: -18px;
}

.container .buttonSubmit:hover {
    opacity: 0.8;
    transition: 200ms;
}

.buttonAdd {
    background-color: var(--green);
    padding: 8px 20px;
    color: var(--white);
    font-size: 12px;
    text-transform: capitalize;
    overflow: hidden;
    transition: 0.5s;
    letter-spacing: 1px;
    border: none;
    border-radius: 4px;
    display: block;
    margin-top: -12px;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 0px;
}

.buttonAdd:hover {
    opacity: 0.8;
    transition: 200ms;
}

/* form */
.innerContainer {
    width: 100%;
    background-color: var(--white);
    border-radius: 10px;
    margin-top: 4px;
    padding: 20px 2px;
    padding-bottom: 50px;
    margin-bottom: 40px;
}

.innerMargin {
    margin-bottom: -50px;
}

.innerContainer p {
    display: block;
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 1.1em;
    color: var(--secondary);
    font-weight: 500;
}

.collab {
    width: 95%;
    margin: 0 auto;
    padding: 10px 30px;
    background-color: var(--light-low);
    border-radius: 10px;
    margin-top: 18px;
}

.container > ul > li:nth-child(4) .collab {
    padding-bottom: 40px;
}

.collab label {
    display: block;
    font: 14px;
    color: var(--dark-grey);
    margin-bottom: 14px;
    font-weight: 450;
    margin-top: 10px;
}

.collab label:hover {
    background-color: none;
}

.collab .select {
    background-color: var(--white);
    font-size: 14px;
}

.collab .select option {
    font-size: 14px;
}

.collab input[type='text'],
.collab input[type='email'],
.collab input[type='number'],
.collab input[type='password'],
.collab input[type='date'],
.collab input[type='file'],
.collab input[type='tel'],
.collab input[type='pass'],
.collab textarea,
.collab select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 2px solid var(--white);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.35);
    font-size: 14px;
    color: var(--dark-grey);
    outline: none;
}

.collab input[type='text']:focus,
.collab input[type='email']:focus,
.collab input[type='number']:focus,
.collab input[type='tel']:focus,
.collab textarea:focus {
    border: 2px solid var(--grey2);
}

.formGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.fetchLabel {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 1px 2px 4px var(--light-grey);
    margin-bottom: 20px;
}

.fetchLabel p {
    display: inline;
    position: relative;
    visibility: hidden;
}

.optAll {
    position: absolute;
    margin-top: -2em;
    background-color: var(--light-low);
    width: 40%;
    border-radius: 4px;
    padding: 2px 4px;
    overflow-y: auto;
}

.optSelect {
    box-shadow: 1px 2px 4px var(--light-grey);
    padding: 10px 6px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.optSelect:hover {
    background-color: var(--secondary);
    color: var(--white);
}

.refMargin {
    margin-bottom: 3em;
}

.cap {
    text-transform: capitalize;
}

.formGrid > label > .tk {
    color: var(--green);
}

.message {
    display: block;
    text-align: center;
    color: var(--green);
    font-weight: 500;
    font-size: 14px;
    margin-top: 21px;
    margin-bottom: -20px;
    min-height: 22px;
}

.picture {
    display: block;
    margin: -10px auto;
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: 48% 16%;
    border-radius: 4px;
    margin-top: 6px;
}

@media (max-width: 420px) {
    .containerPopup {
        overflow: auto;
    }

    .formWrapper {
        width: 18em;
        padding: 20px;
        box-shadow: 1px 1px var(--light-low);
        background-color: var(--light);
    }

    .formWrapper .close {
        top: 4px;
        right: 10px;
    }

    .formWrapper .button {
        padding: 6px 10px;
        font-size: 8px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .formWrapper > p {
        margin-top: 12px;
        padding-bottom: 0px;
        font-size: 12px;
    }

    .formWrapper > p > span {
        font-size: 14px;
    }

    .formWrapper .innerWrap {
        margin-top: 1em;
        padding: 0px;
        background-color: var(--light);
        height: 20em;
    }

    .container .labelMain {
        padding: 14px 30px;
        font-size: 12px;
    }

    .innerContainer {
        padding: 6px 0px;
    }

    .collab {
        padding: 12px;
    }

    .container > ul > li:nth-child(4) .collab {
        padding: 12px;
    }

    .formWrapper label {
        font-size: 12px;
    }

    .formWrapper .formMargin {
        margin-top: -28px;
    }

    .formGrid label:nth-child(2) {
        margin-top: -28px;
    }

    .formWrapper .select {
        font-size: 12px;
    }

    .formWrapper .select option {
        font-size: 10px;
    }

    .collab input[type='text'],
    .collab input[type='email'],
    .collab input[type='number'],
    .collab input[type='password'],
    .collab input[type='date'],
    .collab input[type='file'],
    .collab input[type='tel'],
    .collab textarea,
    .collab select {
        padding: 6px;
        font-size: 12px;
    }

    .collab input[type='date'],
    .collab input[type='datetime-local'] {
        font-size: 10px;
    }

    .formWrapper .buttonSubmit {
        padding: 6px 20px;
        font-size: 10px;
        margin-bottom: 10px;
    }

    .buttonAdd {
        padding: 6px 10px;
        font-size: 8px;
        margin-bottom: 10px;
    }

    .formGrid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .fetchLabel {
        padding: 6px;
        font-size: 12px;
    }

    .optAll {
        margin-top: -6em;
        width: 82%;
    }

    .optSelect {
        font-size: 10px;
    }

    .message {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .picture {
        display: block;
        margin: 0px auto;
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 4px;
        margin-top: 0px;
    }
}
