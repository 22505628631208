.close {
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-bottom: 10px;
}
.close > button {
    display: flex;
    justify-content: center;
    place-items: center;
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: #f86809;
    background: #fff1e6;
}
.close > button:hover {
    opacity: 0.8;
}
.header {
    font-weight: 500;
    color: var(--dark-grey);
    margin: auto;
    font-size: 16px;
}
.content {
    padding: 0px 5%;
    padding-top: 0px;
    margin-bottom: 40px;
}
hr {
    border: 0px;
    height: 1px;
    background: #fff1e6;
}
.main {
    background: var(--light-low);
    border-radius: 6px;
    padding: 20px 15px;
    padding-bottom: 13px;
    overflow-y: auto;
    margin-top: 20px;
    /* height: 55vh; */
}
.main > div > input {
    display: none;
}
.main > div > input[type='checkbox'] ~ div {
    height: 0;
    transform: scaleY(0);
}
.main > div > input[type='checkbox']:checked ~ div {
    height: 100%;
    transform-origin: top;
    transition: transform 0.5s ease-out;
    transform: scaleY(1);
    margin-bottom: 20px;
}
.main > div > input[type='checkbox']:checked ~ label {
    background: var(--primary);
    color: var(--white);
}
.formItem {
    background: var(--white);
    margin-bottom: 10px;
    padding: 15px 25px;
    box-shadow: 1px 2px 12px var(--light-grey);
    border-radius: 6px;
    color: var(--dark-grey);
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    display: block;
}
.formItem:hover {
    cursor: pointer;
    background: var(--primary);
    color: var(--white);
    opacity: 0.8;
}
.main > div:last-child {
    margin-bottom: 0px;
}
