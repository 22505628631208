.container {
    background: var(--white);
    border-radius: 10px;
}
.whiteContainer {
    padding: 20px;
}

.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
}
.innerContainer > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 450;
}
.innerContainer > textarea[type='text'] {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;

    padding-left: 12px;
    min-height: 150px;
}
.innerContainer > textarea:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.half {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 30px;
    font-weight: 450;
}
.half > div {
    width: 100%;
}
.half > div > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 6px;
}
.half > div > input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;

    padding-left: 12px;
}
.half > div > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.permanent {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-items: end;
}
.permanent > div {
    grid-column-start: 2;
}

.permanent > div > button {
    border: none;
    padding: 10px 20px;
    background: var(--secondary);
    color: var(--white);
    font-weight: 600;
    border-radius: 6px;
}
.permanent > div > button:hover {
    opacity: 0.8;
    cursor: pointer;
}
