/* .tableList {
    width: 100%;
}

.tableMain {
    border-collapse: separate;
    border-spacing: 0 0px;
    display: table;
    width: 100%;
}
.tableMain tr th {
    font-size: 13px;
    padding: 20px 12px 20px 12px;
    font-weight: 600;
    background-color: var(--white);
    border-bottom: 1px solid #dde4ee;
    color: var(--dark);
}
.tableMain > thead > tr > th:first-child {
    border-radius: 6px 0px 0px 0px;
}
.tableMain > thead > tr > th:last-child {
    border-radius: 0px 6px 0px 0px;
}
.tableMain tr td {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: var(--dark);
    padding: 10px;
    background-color: var(--white);
    border-bottom: 1px solid #dde4ee;
    text-transform: capitalize;
}
.tableMain button,
.tableMain a {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: rgb(18, 150, 136);
}

.tableMain button:hover,
.tableMain a:hover {
    opacity: 0.8;
    transition: 250ms;
    cursor: pointer;
} */

.tableContainer {
    overflow-x: auto;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
}

.table {
    background-color: var(--white);
    width: 100%;
    min-width: 1400px;
    border-collapse: collapse;
    text-align: left;
    font-size: 0.875rem;
}

.table th {
    background-color: var(--white);
    padding: 0.75rem 1rem;
    font-weight: 600;
    color: #374151;
    border-bottom: 1px solid #e5e7eb;
}

.table td {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    color: #4b5563;
}

.table tr:last-child td {
    border-bottom: none;
}
