.toggleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-family: Arial, sans-serif;
    background-color: var(--white);
    width: 100%;
    padding: 12px 1em;
    border-radius: 7px;
    box-shadow: 1px 2px 12px #dee2e9;
}

.label {
    font-size: 14px;
    color: #333;
}

.toggleSwitch {
    width: 55px;
    height: 24px;
    background: #ddd;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    transition: background 0.3s ease-in-out;
}

.toggleSwitch.on {
    background: var(--primary);
}

.toggleCircle {
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    left: 4px;
    transition: transform 0.3s ease-in-out;
}

.toggleSwitch.on .toggleCircle {
    transform: translateX(26px);
}

.toggleText {
    font-size: 12px;
    color: #fff;
    position: absolute;
    right: 30px;
    top: 3px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
.toggleTextOff {
    font-size: 11px;
    color: black;
    position: absolute;
    left: 28px;
    top: 4px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}
.toggleSwitch.on .toggleText {
    opacity: 1;
}
