.wrapper {
    overflow-y: scroll;
    padding-right: 0.5rem;
    margin-bottom: 270px;
}

.button {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    background: var(--white);
    padding: 1em;
    border-radius: 5px;
    margin-bottom: 10px;
}

.buttonInactive {
    background-color: #e7e9eba9;
    color: rgb(76, 76, 76);
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    font-size: 14px;
    cursor: pointer;
}

.buttonActive {
    background-color: var(--primary);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    font-size: 14px;
    cursor: pointer;
}
