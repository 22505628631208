.container {
    background: var(--white);
    padding: 13px 20px;
    border-radius: 6px;
    font-size: 14px;
    border: 1px solid #dde0e5;
}
.courierLabel {
    display: flex;
    justify-content: space-between;
    place-items: center;
    cursor: pointer;
}
.courierLabel > p {
    display: flex;
    place-items: center;
    gap: 10px;
    color: #271810;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
}
.courierLabel > p > input {
    outline: none !important;
}
.courierLabel > img {
    height: 20px;
    width: auto;
    object-fit: cover;
    flex-shrink: 0;
    cursor: pointer;
}
.courierLabel > button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    place-items: center;
    color: var(--primary);
    transition: all 0.5s ease;
}
.courierLabel > button:hover {
    opacity: 0.5;
}
/*  */
