.container {
    background: var(--white);
    border-radius: 10px;
}
.whiteContainer {
    padding: 20px;
}

.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
    font-size: 14px;
}
.half {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 30px;
    font-weight: 450;
    grid-auto-rows: min-content;
    grid-auto-flow: dense;
}
.half > div {
    width: 100%;
}
.radio {
    display: flex;
    place-items: center;
    gap: 1.5em;
    row-gap: 0.8em;
    border: 1px solid var(--primary);
    padding: 8px;
    border-radius: 6px;
    flex-wrap: wrap;
    align-self: flex-start;
}
.radio > div {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0px 5px;
    color: var(--dark-grey);
    font-weight: 500;
}
.radio > span {
    flex-basis: 100%;
    color: #3f3d56;
    font-size: 12px;
    font-weight: 400;
    margin-left: 3em;
}
