.tableWrapper {
    border: none;
    max-width: 100%;
    /* overflow-y: scroll; */
    scrollbar-width: thin;
    scrollbar-color: #c5c5c5 #f6f6f6;
    /* max-height: 43em; */
    cursor: default;
}

.tableWrapper::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

.tableWrapper::-webkit-scrollbar-track {
    background: #f6f6f6;
}

.tableWrapper::-webkit-scrollbar-thumb {
    background-color: #929292;
    border-radius: 20px;
    border: none;
}

.table {
    border-collapse: separate;
    border-spacing: 0 4px;
    display: table;
    width: 100%;
}

.table tr {
    box-shadow: 0 2px 3px var(--light);
}

.table tr th {
    box-shadow: 0px 0px 0px 1px var(--light);
    font-weight: 500;
    font-size: 13px;
    padding: 12px;
    background-color: var(--primary);
    border-radius: 4px;
    color: var(--white);
    position: sticky;
    top: 0px;
}

.table tr td {
    text-align: center;
    font-size: 13px;
    color: var(--dark);
    padding: 10px;
    background-color: var(--white);
    border-radius: 4px;
}
@media (max-width: 420px) {
    table {
        margin-bottom: 6px;
        padding-right: 5px;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead tr {
        display: none;
    }

    tr {
        margin: 0 0 1rem 0;
    }

    .table tr td {
        padding-left: 100px;
        background-color: var(--white);
        border-bottom: 1px solid var(--light-low);
        font-size: 12px;
    }
}
