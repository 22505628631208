.wrapper {
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.2), 0 0.125rem 0.1875rem 0.06rem rgba(0, 0, 0, 0.24);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: max-content;
    padding: 2rem 4rem;
    transform: translate(-50%, -50%);
    z-index: 200;
    color: rgb(35, 35, 35);
}

.wrapper > div:nth-child(1) > p {
    color: var(--primary);
    font-size: 1.2rem;
    text-align: center;
}

.wrapper > div:nth-child(2) {
    margin-top: 2rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.wrapper > div:nth-child(2) > button {
    border: none;
    border-radius: 0.2rem;
    padding: 0.4rem 1.2rem;
    color: white;
    cursor: pointer;
}

.wrapper > div:nth-child(2) > button:nth-child(1) {
    background-color: var(--green);
}

.wrapper > div:nth-child(2) > button:nth-child(2) {
    background-color: var(--deep-red);
}

.wrapper > div:nth-child(2) > button:nth-child(1):hover {
    background-color: rgb(76, 149, 76);
}

.wrapper > div:nth-child(2) > button:nth-child(2):hover {
    background-color: rgb(200, 56, 56);
}
