.productTable {
    padding-bottom: 16px;
}
.productTable table {
    /* border-collapse: collapse; */
    border-spacing: 0;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(242, 242, 242, 1);
    border: 1px solid #dde0e5;
}

.productTable thead {
    background-color: #f1f1f1;
}

.productTable th,
.productTable td {
    border-right: 1px solid #dde0e5;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid #dde0ef;
    text-transform: capitalize;
}
.productTable th span {
    font-size: 13px;
    color: var(--primary);
    background: #fff;
    border: 1px solid #f6d8c8;
    width: fit-content;
    display: block;
    padding: 2px 10px;
    border-radius: 21px;
    margin: 5px auto auto auto;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
}
/* .productTable td:last-child {
    border-bottom: none;
} */

.productTable th {
    background-color: #f4f4f4;
    color: var(--text-bold-color);
    font-weight: 500;
}

.productTable td {
    color: var(--text-light);
}
/* .productTable td:first-child {
    text-align: left;
} */
.productTable input,
.productTable input:focus {
    max-width: 120px;
    padding: 5px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: inset 0 1.2px 1px 0 rgba(183, 183, 183, 1);
    color: var(--text-light);
    font-size: 14px;
    line-height: 21px;
    max-height: 30px;
}
.productTable input::placeholder {
    color: var(--text-light);
}
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total {
    font-size: 18px;
    font-weight: bold;
}

.total span {
    color: #ff6600;
}

.buttons {
    display: flex;
    gap: 10px;
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.btn.back {
    background-color: #f0f0f0;
    color: #333;
}

.btn.continue {
    background-color: #ff6600;
    color: white;
}
.footer {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 8px 10px;
    width: fit-content;
    border: 1px solid #f6d8c8;
    display: inline-block;
    font-size: 13px;
    line-height: 23px;
}
.footer span {
    font-weight: 600;
    color: var(--primary);
}
input.wrongInput {
    border: 1px solid red;
}
tbody {
    position: relative;
}
.displayVariantButton {
    background-color: #ff6d00;
    color: white;
    border: 3px solid #fff;
    font-size: 11px;
    font-weight: bold;
    border-radius: 8px;
    position: absolute;
    left: -122px;
    top: 10px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    white-space: nowrap;
    z-index: 1000;
    width: 115px;
    text-align: center;
    padding: 3px 0;
}

.displayVariantButton::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -8px; /* Adjust for arrow placement */
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: #ff6d00; /* Same as button */
    clip-path: polygon(0 0, 100% 50%, 0 100%);
}
