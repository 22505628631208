button {
    border: 1px solid transparent;
    outline: none;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    background: transparent;
}
.primaryBtn {
    background: linear-gradient(rgba(255, 102, 0, 1), rgba(250, 127, 42, 1));
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
    padding: 6px 14px;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-width: 110px;
    cursor: pointer;
}
.primaryBtn svg path {
    fill: transparent;
}
.defaultBtn {
    text-decoration: underline;
    color: #555555;
    cursor: pointer;
}
