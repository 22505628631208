.titleContainer {
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-bottom: 8px;
    align-items: center;
    background-color: white;
    padding: 1em;
    border-radius: 5px;
}
.titleContainer > h2 {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    gap: 6px;
    place-items: center;
    padding: 0;
    margin: 0;
    color: var(--primary);
}

.titleContainer > p {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 500;
}
.titleContainer > p > span {
    color: var(--primary);
    font-weight: 500 !important;
    font-size: 12px;
}
