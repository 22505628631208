.container {
    background: var(--white);
    border-radius: 10px;
}
.innerContainer {
    width: 98%;
    background: var(--light-low);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 30px;
}
.innerContainer > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 3px;
}
.innerContainer > .upload {
    width: 100%;
    background: var(--white);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    cursor: default;
    display: flex;
    place-items: center;
    justify-content: space-between;
}
.upload > div {
    display: flex;
    place-items: center;
    gap: 10px;
}
.upload > button {
    color: var(--primary);
    border: none;
    background: none;
    display: flex;
    place-items: center;
    cursor: pointer;
}
.upload > div > svg {
    color: var(--primary);
}
