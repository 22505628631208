.layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.layout h2 {
    font-size: 22px;
    line-height: 23px;
    color: var(--text-bold-color);
}
.navigation > div {
    display: flex;
    align-items: center;
    gap: 12px;
}

.navigation {
    display: flex;
    align-items: center;
    gap: 12px;
}

.navigation .active .indicator {
    background: #fff4ee;
    border: 2px solid var(--primary);
}

.navigation .indicator {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: #f6f6f6;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation h3 {
    font-size: 14px;
    line-height: 21px;
    color: #555;
    text-transform: capitalize;
}
.navigation .active h3,
.navigation .completed h3 {
    color: var(--text-bold-color);
}
.navigation svg path {
    fill: transparent;
}

.completed .indicator {
    background: var(--primary);
    border: 1px solid var(--primary);
}

.completed svg path {
    fill: #fff;
}

.line {
    display: contents;
}

.completed .line svg path {
    stroke: var(--primary);
}
.btnGroup {
    display: flex;
    gap: 20px;
    align-items: center;
}
.footer {
    text-align: end;
    display: flex;
    justify-content: end;
}
