.container {
    background: var(--white);
    padding: 1.5em 2em;
    border-radius: 8px;
    box-shadow: 3px 3px 15px var(--light-grey);
    margin-top: 2em;
    margin-bottom: 180px;
}
.wrapper {
    margin-top: 2em;
}
.wrapper > h1 {
    font-size: 16px;
    font-weight: 600;
}
.innerContainer {
    width: 98%;
    background: var(--light-low);
    /* margin: 0 auto; */
    padding: 10px 30px;
    margin-top: 2em;
    border-radius: 10px;
}
.selectContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    gap: 1.5em;
    place-items: center;
    position: relative;
}
.selectContainer > p {
    font-size: 14px;
    font-weight: 450;
}
.selector {
    flex-grow: 1;
    font-size: 14px;
    border: 1px solid var(--primary);
    padding: 5px;
    padding-left: 6px;
    border-radius: 6px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    background-color: var(--white);
    outline: none;
}
.selector {
    outline: none;
    border: 1px solid var(--deep-primary);
}
.selectArrow {
    position: absolute;
    right: 10px;
    pointer-events: none;
    color: var(--deep-primary);
}
.half {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 30px;
    font-weight: 450;
}
.half > div {
    width: 100%;
}
.half > div > p {
    margin: 0px;
    color: var(--dark-grey);
    font-size: 14px;
    margin-bottom: 6px;
}
.half > div > input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    box-shadow: inset 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    border: none;

    padding-left: 12px;
}
.half > div > input:focus {
    box-shadow: inset 1px 2px 4px var(--grey2);
    outline: none;
}
.fixedField {
    width: 100%;
    background: var(--white);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px var(--light-grey);
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--dark);
    cursor: default;
    min-height: 36px;
}
.submitMessage {
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 600;
}
.submit {
    display: flex;
    justify-content: center;
    place-items: center;
    margin-top: 2em;
    margin-bottom: 0.5em;
}
.submit > button {
    border: none;
    background: var(--primary);
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
}
.submit > button:hover {
    opacity: 0.8;
}
