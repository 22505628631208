.container {
    background: #ff6700;
}
.content {
    background: #1b497e;
    margin-left: 8px;
    padding: 6px;
    font-size: 14px;
    font-weight: 700;
    color: var(--white);
    line-height: 16.1px;
}
