.filter {
    background-color: var(--white);
    padding: 1em;
}

.titleIcon {
    font-size: 22px;
    margin-bottom: -6px;
}
.filterTitle > p {
    font-size: 14px;
}
.inputField {
    display: flex;
    align-items: center;
    gap: 20px;
}
.search {
    /* width: 30%; */
    display: flex;
    align-items: center;
    gap: 20px;
}
.search > input {
    width: 100%;
    border: 1px rgb(177, 177, 177) solid;
    padding: 7px 10px;
    border-radius: 0.3rem;
    outline: none;
}
.search > input::placeholder {
    font-size: 11px;
    font-weight: 500;
}

.search > input:focus {
    border: 1px solid var(--primary);
}

.filtersRow {
    display: flex;
    gap: 0.75rem;
    margin-top: 20px;
    align-items: center;
    flex-wrap: wrap;
    background: #f9fafb;
    padding: 10px;
    border-radius: 10px;
}
.checkboxes,
.radioBtn {
    display: flex;
    gap: 13px;
    cursor: pointer;
}
.checkboxes > div,
.radioBtn > div {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    padding: 10px 15px;
    height: 36px;
    cursor: pointer;
}

.checkboxes .active {
    background: #fff;
    color: #000;
    box-shadow: 0 2px 5px 1px #e7e7e7;
}
.checkboxes > div > div {
    display: inline-flex;
}
.checkboxes svg {
    border: 2px solid #757575;
    border-radius: 3px;
}
.checkboxes .active svg {
    border: none;
}
.checkboxes svg path {
    fill: transparent;
}
.checkboxes .active svg path {
    fill: revert-layer;
}
.sortContainer {
    margin-left: auto;
    position: relative;
}
.searchContainer {
    margin-bottom: 20px;
}

.searchInputWrapper {
    position: relative;
    max-width: 100%;
}

.searchIcon {
    position: absolute;
    top: 12px;
    left: 10px;
    color: #fff;
    color: var(--primary);
}

.searchInput {
    width: 100%;
    padding: 12px 16px 12px 60px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 0.875rem;
    outline: none;
    transition: border-color 0.2s;
}
.popup {
    margin-top: 20px;
}
