.formContainer {
    padding: 10px;
    padding-bottom: 5px;
    background: var(--light-low);
    border-radius: 6px;
}
.main {
    background: var(--light-low);
    border-radius: 6px;
    padding: 20px 15px;
    padding-bottom: 13px;
}
.main > div > input {
    display: none;
}
.main > div > input[type='checkbox'] ~ div {
    height: 0;
    transform: scaleY(0);
}
.main > div > input[type='checkbox']:checked ~ div {
    height: 100%;
    transform-origin: top;
    transition: transform 0.5s ease-out;
    transform: scaleY(1);
    margin-bottom: 20px;
}
.main > div > input[type='checkbox']:checked ~ label {
    background: var(--primary);
    color: var(--white);
}
.formItem {
    background: var(--white);
    margin-bottom: 13px;
    padding: 20px 30px;
    box-shadow: 1px 2px 12px var(--light-grey);
    border-radius: 6px;
    color: var(--dark-grey);
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    display: block;
    transition: all 0.5s ease;
}
.formItem:hover {
    cursor: pointer;
    background: var(--primary);
    color: var(--white);
    opacity: 0.8;
}
.main > div:last-child {
    margin-bottom: 0px;
}
.submitContainer {
    display: flex;
    justify-content: center;
    place-items: center;
    margin-bottom: 20px;
}
.submitContainer > button {
    border: none;
    padding: 8px 16px;
    background: var(--primary);
    color: var(--white);
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.5s ease;
}
.submitContainer > button:hover {
    opacity: 0.8;
}
