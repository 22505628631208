:global(.sortContainer) {
    position: relative !important;
}
:global(.sortContainer) :global(button) {
    border-radius: 8px !important;
}

.popUpDropDown {
    position: absolute;
    right: -3px;
    top: 0;
}
.popUpDropDown :global(button) {
    height: 47px;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 16px;
    border-radius: 0 8px 8px 0;
    min-width: 60px;
    color: #000 !important;
    font-weight: 400;
}
.popUpDropDown > div {
    width: fit-content;
}

.popUpDropDown :global(.react-select__indicator-separator) {
    display: none;
}

.popUpDropDown svg path {
    fill: #757575;
}

.popUpDropDown :global(.css-1xbxjus),
.popUpDropDown :global(.css-snczsu) {
    min-width: 50px;
    height: 42px;
    background: #f1f1f2;
}
.popUpDropDown :global(.css-snczsu) {
    background: var(--pink);
}
.popUpDropDown :global(.css-1979g2e) {
    display: flex;
    align-items: center;
    gap: 6px;

    margin-bottom: 0 !important;
}
.popUpDropDown :global(.css-13cymwt-control) {
    display: none;
}
.popUpDropDown :global(.react-select__input-container) {
    padding: 8px 0;
    margin: 0;
}
.popUpDropDown :global(.react-select__menu-list) {
    padding: 10px;
}

.popUpDropDown :global(.css-qr46ko > div),
.popUpDropDown :global(.css-1979g2e),
.popUpDropDown :global(.css-8knsiu) {
    font-size: 13px;
    line-height: 23px;
}

.popUpDropDown :global(.react-select__menu-notice) {
    font-size: 12px;
    line-height: 21px;
    color: #757575;
    padding: 0;
}
.popUpDropDown :global(.css-qr46ko) {
    padding: 0;
    margin: 0;
}
.popUpDropDown :global(.css-1nmdiq5-menu) {
    width: 180px;
    left: -110px;
    margin: 0;
}

.popUpDropDown :global(.css-1979g2e) {
    background: #f1f1f2;
}
.popUpDropDown :global(.css-8knsiu),
.popUpDropDown :global(.css-1979g2e):hover {
    background: var(--pink) !important;
    color: #000 !important;
}
.popUpDropDown :global(.css-d7l1ni-option),
.popUpDropDown :global(.css-tr4s17-option) {
    background: var(--pink);
    color: #000;
    border-radius: 6px;
}
.popUpDropDown :global(.react-select__option--is-selected) {
    margin: 4px 0;
}

/* focus */

.popUpDropDown :global(.react-select__control--is-focused) {
    border: 1px solid var(--primary) !important;
    box-shadow: 0 0 0 2px #ffe6d8;
    outline: none;
}
.popUpDropDown :global(.react-select__control--is-focused) :global(.react-select__indicators) {
    background: var(--pink) !important;
}
.popUpDropDown :global(.react-select__control--is-focused) svg path {
    fill: #000;
}

/* scrollbar */

.popUpDropDown :global(.css-qr46ko)::-webkit-scrollbar {
    width: 5px;
}

.popUpDropDown :global(.css-qr46ko)::-webkit-scrollbar-track {
    background: #f1f1f2;
    border-radius: 0px;
}

.popUpDropDown :global(.css-qr46ko)::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
}

.popUpDropDown :global(.css-qr46ko)::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
}
