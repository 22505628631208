.tabContainer {
    background: var(--white);
    border-radius: 10px;
    padding: 5px;
    padding: 16px;
    box-shadow: 0px 3px 10px 0px #f2f2f2;
}

.tabs {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    overflow-x: auto;
}
.tabs::-webkit-scrollbar {
    width: 8px;
}

.tabs::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 4px;
}

.tabs::-webkit-scrollbar-thumb:hover {
    background-color: var(--deep-primary);
}

.tabs::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
