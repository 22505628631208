.wrapper {
    border-radius: 0.5rem;
    background-color: var(--white);
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.2), 0 0.125rem 0.1875rem 0.06rem rgba(0, 0, 0, 0.24);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: max-content;
    padding: 0.5rem;
    transform: translate(-50%, -50%);
    z-index: 107;
    color: rgb(35, 35, 35);
}

.loading {
    position: absolute;
    top: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    border-radius: 0.6rem 0.6rem 0 0;
    overflow: hidden;
}

.title {
    text-align: center;
    font-weight: 600;
    font-size: 1.3rem;
    color: var(--primary);
    display: flex;
    flex-direction: column;
}

.title > div:nth-child(2) {
    font-size: 0.9rem;
    color: var(--dark-blue);
}

.action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 2rem 1rem 1rem 1rem;
}

.toggle {
    background-color: lightgrey;
    border-radius: 2rem;
    padding: 0.4rem 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.action > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.action > div > div:nth-child(1) {
    color: rgb(63, 63, 63);
}

.active {
    background-color: darkgreen;
    color: white;
    border-radius: 2rem;
    padding: 0.25rem 1rem;
    cursor: pointer;
}

.inactive {
    background-color: darkred;
    color: white;
    border-radius: 2rem;
    padding: 0.25rem 1rem;
    cursor: pointer;
}

.toggleBtn {
    background-color: white;
    color: grey;
    border-radius: 2rem;
    padding: 0.2rem 1rem;
    cursor: pointer;
}

.subscriptionPlan {
    margin: 2rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: rgb(77, 77, 77);
}

.passwordReset {
    margin: 2rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.passwordReset > div:nth-child(1) {
    color: rgb(63, 63, 63);
}

.resetEmpty {
    display: none;
}

.reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
}

.reset > input {
    border: 1px lightgrey solid;
    border-radius: 0.3rem;
    padding: 0.5rem;
    outline: none;
}

.reset > input:focus {
    border-bottom: 2px var(--dark-blue) solid;
}

.reset > button {
    border: none;
    border-radius: 0.2rem;
    padding: 0.5rem 1.5rem;
    background-color: var(--dark-blue);
    color: #fff;
}

#resetTitle:hover {
    color: var(--blue);
    text-decoration: underline;
}

.close {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.closeBtn {
    color: var(--deep-red);
    font-size: 1rem;
}

.closeBtn:hover {
    color: rgb(219, 24, 24);
    cursor: pointer;
}
