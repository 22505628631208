.loading {
    border: none;
    border-radius: 0.3rem;
    padding: 0.4rem 1rem;

    display: block;
    width: 100%;
    text-align: center;
    margin: 1rem auto;
}
